import { Company } from 'src/models/Company'
import { DataObject } from 'src/models/DataObject'
import { User } from 'src/models/User'
import { sharedDataStore } from 'src/store/DataStore'

export const ALL_PARTNERS_GROUP = 'All Partners'
export const ALL_PUBLIC_USERS_GROUP = 'All Public Users'

export class Group extends DataObject {
  static apiEndpoint: string = '/api/groups/'
  static readonly EXPORT_TO_CSV_FIELDS = 'author,created_at,active_users'
  static OVERRIDE_MAPPINGS = {
    key: (data) => data.id,
    company: (data) => (data.company ? Company.fromData(data.company) : undefined),
    users: (data) => (data.users ? User.fromData(data.users) : []),
  }

  key: string
  id: string
  name: string
  company: Company
  users: User[] = []
  activeUsersCount: number
  activeUsers: User[] = []

  isAllCompanyGroup = () => {
    return this.id === sharedDataStore.user.company.defaultGroupId
  }

  isAllPartnersGroup = () => {
    return this.name === ALL_PARTNERS_GROUP
  }

  isAllPublicUsersGroup = () => {
    return this.name === ALL_PUBLIC_USERS_GROUP
  }

  /*
  This method takes a list of parent groupIds and a list of groups and returns the parent groups and any subgroups
  whose active users are entirely within the parent groups user base. Here, a "subgroup" is a group where every active user
  is also a user in one of the specified parent groups.
   */
  static getGroupsAndSubGroups = (parentGroupIds: Array<string>, allGroups: Array<Group>) => {
    const parentGroupsUserIds: Set<string> = new Set()

    for (const group of allGroups) {
      if (parentGroupIds.includes(group.id)) {
        group.activeUsers.forEach((user) => parentGroupsUserIds.add(user.id))
      }
    }

    return allGroups
      .filter((group) => {
        if (parentGroupIds.includes(group.id)) return true
        return group.activeUsers.every((user) => parentGroupsUserIds.has(user.id))
      })
      .sort((a, b) => a.name.localeCompare(b.name))
  }
}
