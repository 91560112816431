import { observable, computed, makeObservable } from 'mobx'
import { makePersistable, hydrateStore } from 'mobx-persist-store'
import { Config } from 'src/models/Config'
import { User } from 'src/models/User'
import { getQueryParam } from 'src/utils/urlParams'
import { ReferralCodeQueryKey } from 'src/constants/app'
import { Paths } from 'src/constants/navigation'
import { FeedPost } from 'src/models/FeedPost'
import { QueueItem } from 'src/models/QueueItem'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { camelToSentenceCase } from 'src/utils/format'
import { Asset } from 'src/models/Asset'
import { Prompt } from 'src/models/Prompt'
import { Group } from 'src/models/Group'

const DATA_STORE_PERSIST_KEY = 'DATA_STORE_PERSIST_KEY'
const APP_STORE_PERSIST_KEY = 'APP_STORE_PERSIST_KEY'
const USER_STORE_PERSIST_KEY = 'USER_STORE_PERSIST_KEY'

const ENABLE_FEATURE_COMPANIES = ['Flockjay']

export type AuthStateType = 'loading' | 'unauthorized' | 'authorized' | 'not-found'

export type ViewMode = 'card' | 'list' | 'table'

export const ViewModes: ViewMode[] = ['card', 'list', 'table']

type LocalSettings = {
  autoplayClassroomOn: boolean
  playbackRate: number
  showHomepageAdminChart: boolean
  selectedHomepageAdminChat: string
  hideResponseFromFeed: boolean
  enforceMediaCompletion: boolean
  requireSequentialCompletion: boolean
  viewMode: ViewMode
}

type QueueContainer = {
  sentRecentQueueItemsReminder: boolean
  assigned: { items: QueueItem[]; hasMore: boolean }
  completed: { items: QueueItem[]; hasMore: boolean }
}
interface GlobalQueue {
  [userId: string]: QueueContainer
}

class DataStore {
  @observable referralCode: string
  @observable appId: string
  @observable user: User = new User()
  @observable utmData = {}
  @observable trackingData = {}
  @observable localSettings: LocalSettings = {
    autoplayClassroomOn: true,
    playbackRate: 1,
    showHomepageAdminChart: true,
    selectedHomepageAdminChat: 'taskCompletion',
    hideResponseFromFeed: true,
    enforceMediaCompletion: true,
    requireSequentialCompletion: true,
    viewMode: 'card',
  }
  @observable videoWatchData = {}
  @observable viewTrackingData = {}

  @observable selectedJobOpportunity: string = ''
  @observable config: Config = new Config()
  @observable authState: AuthStateType = 'loading'

  handleContentCreated?: (obj?: FeedPost | Prompt | Asset) => void = undefined
  updateContextLearningPathProgess?: () => Promise<void> = undefined

  @observable queue: GlobalQueue = {}

  refreshQueue = async (
    queryParams: object = { user_id: sharedDataStore.user.id },
    setLoading?: (loading: boolean) => void,
    errorHandler?: (err: Error) => void
  ) => {
    if (setLoading) setLoading(true)
    try {
      if (!queryParams['completed']) queryParams['completed'] = false

      const userId = queryParams['user_id']
      const page = queryParams['page'] || 1
      if (!this.queue[userId])
        this.queue[userId] = {
          sentRecentQueueItemsReminder: false,
          assigned: { items: [], hasMore: false },
          completed: { items: [], hasMore: false },
        }

      const updateQueue = (page: number, existingData: QueueItem[], newData: QueueItem[]) =>
        page === 1 ? newData : [...existingData, ...newData]

      const res = await QueueItem.list(queryParams)
      if (queryParams && !queryParams['completed']) {
        this.queue[userId].assigned.items = updateQueue(page, this.queue[userId].assigned.items, res.data)
        this.queue[userId].assigned.hasMore = !!res.next
        if (userId === this.user.id) sharedDataStore.user.incompleteQueueItemCount = res.count
      } else if (queryParams && queryParams['completed'] === true) {
        this.queue[userId].completed.items = updateQueue(page, this.queue[userId].completed.items, res.data)
        this.queue[userId].completed.hasMore = !!res.next
      }
      this.queue[userId].sentRecentQueueItemsReminder = res.sentRecentQueueItemsReminder
      this.queue = { ...this.queue } // only top level object is observable so have to pulse it
    } catch (err) {
      if (errorHandler) errorHandler(err)
      else sharedAppStateStore.handleError(err)
    } finally {
      if (setLoading) setLoading(false)
    }
  }

  objectPresentInQueue = (objId: string, userId: string = sharedDataStore.user.id) => {
    const queueData = this.getQueueForUser(userId)
    return !![...queueData.assigned.items, ...queueData.completed.items].find((q) => q.linkedObjectId === objId)
  }

  userCanModifyQueueItem = (objId: string, userId: string = sharedDataStore.user.id) => {
    if (sharedDataStore.user.isFaasAdminOrManager()) return true
    const queueData = this.getQueueForUser(userId)
    const queueItem = [...queueData.assigned.items, ...queueData.completed.items].find(
      (q) => q.linkedObjectId === objId
    )
    if (!queueItem) return true
    return queueItem.addedBy === sharedDataStore.user.id
  }

  getQueueForUser = (userId: string): QueueContainer => {
    return (
      this.queue[userId] || {
        sentRecentQueueItemsReminder: false,
        assigned: { items: [], hasMore: false },
        completed: { items: [], hasMore: false },
      }
    )
  }

  constructor() {
    makeObservable(this)
    makePersistable(this, {
      name: DATA_STORE_PERSIST_KEY,
      properties: [
        'referralCode',
        'appId',
        'user',
        'utmData',
        'trackingData',
        'localSettings',
        'videoWatchData',
        'viewTrackingData',
      ],
      storage: window.localStorage,
    })
  }

  async hydrateStore() {
    await hydrateStore(this)
    await this.hydrate_backward_compatibility()

    // Make sure if user is an actul User object.
    if (!(this.user instanceof User)) {
      this.user = User.fromData(this.user)
    }
  }

  async hydrate_backward_compatibility() {
    if (localStorage.getItem(DATA_STORE_PERSIST_KEY)) return

    const appStoreStorage = JSON.parse(localStorage.getItem(APP_STORE_PERSIST_KEY))
    const userStoreStorage = JSON.parse(localStorage.getItem(USER_STORE_PERSIST_KEY))

    if (appStoreStorage) {
      this.referralCode = appStoreStorage.referralCode
      this.appId = appStoreStorage.app && appStoreStorage.app.id
    }
    if (userStoreStorage) {
      this.user = User.fromData(userStoreStorage.user)
    }

    // Will allow this after 2 or 3 months later
    if (process.env.REACT_APP_REMOVE_OLD_STORAGE) {
      localStorage.removeItem(APP_STORE_PERSIST_KEY)
      localStorage.removeItem(USER_STORE_PERSIST_KEY)
    }
  }

  setReferralCode = () => {
    const referralCode = getQueryParam(ReferralCodeQueryKey)
    if (referralCode) {
      this.referralCode = referralCode
    }
  }

  getLoginHandlerPath = () => {
    if ((this.user.isAnonymous() || this.user.isFaasPublic()) && sharedAppStateStore.externalAcademy?.data.customHome)
      return sharedAppStateStore.externalAcademy?.data.customHome
    if (!(this.user instanceof User)) return Paths.login
    else if (this.user.isFaasPublic()) return Paths.library
    else if (this.user.isFaasUser()) return Paths.feed
    else if (this.user.isAnonymous() && !!sharedAppStateStore.externalAcademy)
      return Paths.getAcademyPath(sharedAppStateStore.externalAcademy.urlPath)
    return Paths.login
  }

  @computed get enableCompanyFeatures() {
    return ENABLE_FEATURE_COMPANIES.includes(this.user.company.name)
  }

  @computed get stageNameOptions() {
    return (this.config?.opportunityStageNames ?? []).map((item: string) => ({
      key: item,
      id: item,
      value: item,
      label: item,
    }))
  }

  @computed get customMetadataFieldNames() {
    return Object.keys(this.config.learningContentCustomFields ?? {})
  }

  @computed get assetFileTypeOptions() {
    return (this.config?.assetFileTypes ?? []).map((item: string) => ({
      key: item,
      id: item,
      value: item,
      label: item === 'pdf' ? 'PDF' : camelToSentenceCase(item),
    }))
  }
  @computed get groupOptions() {
    return (this.user.groups ?? []).map((group: Group) => ({
      key: group.id,
      id: group.id,
      value: group.id,
      label: group.name,
    }))
  }
}

const sharedDataStore = new DataStore()
export { sharedDataStore }
