import moment from 'moment'
import { JsonTree } from '@react-awesome-query-builder/antd'
import { DataObject } from 'src/models/DataObject'
import { Author } from 'src/models/Author'
import { sharedDataStore } from 'src/store/DataStore'
import { Colors } from 'src/constants/colors'
import { SfOpportunity } from 'src/models/SfOpportunity'
import { AppearsInType, LearningContentType } from 'src/utils/content'

export class Milestone extends DataObject {
  static OVERRIDE_MAPPINGS = {
    appearsIn: ({ appearsIn }) => appearsIn ?? [],
    author: ({ author }) => (author ? Author.fromData(author) : undefined),
    isFavourited: () => false,
  }

  static shouldUseCache: boolean = true

  static apiEndpoint: string = '/feed/milestone/'

  getBulkEditFields = () => []

  learningContentType: LearningContentType = 'milestone'

  id: string
  description: string
  learningContentId: string
  author: Author
  title: string
  isActive: boolean
  preCondition: object
  queryTree: JsonTree
  progress: number

  isFavourited: boolean = false

  sfMetadata?: SfOpportunity
  expiryDate?: moment.Moment
  appearsIn: AppearsInType[] = []

  createdAt: moment.Moment
  lastUpdated: moment.Moment

  canEdit = () => !!this.id && (sharedDataStore.user.id === this.author.authorID || sharedDataStore.user.isFaasAdmin())

  canArchive = () => false

  canAddToCollection = () => false

  canAddToHub = () => false

  canAddToLearningPath = () => true

  canDelete = () => this.canEdit()

  canAddToPromotedSearch = () => false

  canDuplicate = () => false

  canCreateTemplate = () => false

  getHeroBgColor = () => Colors.titanWhite
  getContentTypeTagColor = () => Colors.perano
  getTagBgColor = () => Colors.lightTitanWhite
  getTagTitle = () => 'Milestone'

  hasOpportunity = () => !!this.sfMetadata?.id

  hasStageName = () => !!this.sfMetadata?.stageName

  imgSrc = () => null
}
