import React, { Component, useCallback, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { Row, Col, Tooltip, Space, Upload, Image, MenuProps, DropdownProps } from 'antd'
import {
  Edit3,
  List,
  Share,
  Trash,
  Trash2,
  ChevronDown,
  Menu,
  Video,
  X,
  MoreHorizontal,
  ThumbsUp,
  ThumbsDown,
} from 'react-feather'
import { observable, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import { FjText, TextSizeOption, ContainerDiv, TextWeightOption, Loader, FjDropdown } from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import SalesforceIcon from 'src/assets/icons/Salesforce.svg'
import GongIcon from 'src/assets/img/gong.png'
import ZoomIcon from 'src/assets/img/zoom.svg'
import OutreachIcon from 'src/assets/img/outreach.png'
import { CSSProps } from 'src/components/Common/Text'
import { ButtonSizeOption, getButtonFontSize, getButtonSize } from 'src/components/Common/Styles'
import { Paths } from 'src/constants/navigation'
import ClapOutlined from 'src/assets/icons/ClapOutlined.svg'
import ClapFilled from 'src/assets/icons/ClapFilled.svg'
import StarFilled from 'src/assets/icons/StarFilledYellow.svg'
import StarEmpty from 'src/assets/icons/StarOutline.svg'
import { mobileQuery } from 'src/pages/public/common'
import { showNotification } from 'src/hoc/Notification'
import { QueueElement, QueueItem } from 'src/models/QueueItem'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { sharedDataStore } from 'src/store/DataStore'
import { LearningContentType, getFeedContentTargetLink, getFeedContentTitle } from 'src/utils/content'
import { AccessRole, User } from 'src/models/User'
import { uploadToS3 } from 'src/utils/S3Upload'
import useDrivePicker from 'react-google-drive-picker'
import { PickerConfiguration, CallbackDoc } from 'react-google-drive-picker/dist/typeDefs'
import GoogleDriveLogo from 'src/assets/icons/GoogleDrive.png'
import { FormikFieldProps } from 'formik-antd/lib/FieldProps'
import { FieldProps, Field } from 'formik'
import { DashboardCategory } from 'src/pages/FeedDashboard'
import { Rubric } from 'src/models/Rubric'
import { EditableElement } from 'src/utils/getMenuContext'
import { TemplateableLearningContentType } from 'src/components/Feed/TemplateAuthoringModal'
import { Template } from 'src/models/Template'
import { TeamTab } from 'src/pages/Team'

const OUTREACH_CLIENT_ID = process.env.REACT_APP_OUTREACH_CLIENT_ID
export const SALESFORCE_CLIENT_ID = process.env.REACT_APP_SALESFORCE_CLIENT_ID
export const GONG_CLIENT_ID = process.env.REACT_APP_GONG_CLIENT_ID
export const ZOOM_CLIENT_ID = process.env.REACT_APP_ZOOM_CLIENT_ID

export type buttonType = 'basic' | 'action-black-light' | 'primary' | 'secondary' | 'tertiary' | 'destructive' | 'text'

declare let google: any

interface ButtonProps extends Omit<React.ComponentPropsWithoutRef<'button'>, 'title'> {
  buttonType?: buttonType
  title?: string | JSX.Element
  width?: string
  image?: JSX.Element
  imagePosition?: 'left' | 'right'
  size?: ButtonSizeOption
  weight?: TextWeightOption
  clicked?: (e: React.MouseEvent) => void
}
interface BasicButtonProps {
  width?: string
  fontSize?: string
  fontWeight?: number
  height?: string
  color?: string
  backgroundColor?: string
  border?: string
  padding?: string
  hover?: {
    color?: string
    backgroundColor?: string
    borderColor?: string
  }
  focus?: {
    color?: string
    backgroundColor?: string
    borderColor?: string
    boxShadow?: string
  }
  disable?: {
    backgroundColor?: string
  }
}

const BasicButton = styled.button<BasicButtonProps>`
  width: ${(props) => props?.width};
  cursor: pointer;
  text-align: left;
  box-shadow: none;
  outline: none;
  letter-spacing: 0.02em;
  text-decoration: none;
  text-align: center;
  font-weight: ${(props) => props?.fontWeight ?? 500};
  vertical-align: middle;
  height: ${(props) => props?.height};
  font-family: inherit;
  font-size: ${(props) => props?.fontSize};
  color: ${(props) => props?.color};
  background-color: ${(props) => props?.backgroundColor ?? 'transparent'};
  border: ${(props) => props?.border ?? 'none'};
  border-radius: 8px;
  padding: ${(props) => props?.padding ?? '0 16px'};
  :disabled {
    cursor: not-allowed;
    background-color: ${(props) => props?.disable?.backgroundColor ?? Colors.mercury};
    border-color: ${Colors.mercury};
    color: ${Colors.tapa};
  }
  :disabled:hover {
    background-color: ${(props) => props?.disable?.backgroundColor ?? Colors.mercury};
    color: ${Colors.tapa};
  }
  :focus {
    color: ${(props) => props?.focus?.color};
    background-color: ${(props) => props?.focus?.backgroundColor};
    border-color: ${(props) => props?.focus?.borderColor};
  }
  :hover {
    color: ${(props) => props?.hover?.color};
    background-color: ${(props) => props?.hover?.backgroundColor};
    border-color: ${(props) => props?.hover?.borderColor};
  }
`

export const DefaultButton: React.FC<ButtonProps & BasicButtonProps> = ({
  type = 'button',
  buttonType = 'basic',
  imagePosition = 'left',
  size = 'medium',
  image,
  title,
  clicked,
  hover,
  focus,
  disable,
  ...props
}) => {
  let fontSize: TextSizeOption | string = getButtonFontSize(size)
  let height: TextSizeOption | string = buttonType === 'text' ? '' : getButtonSize(size)
  let basicButtonProps: BasicButtonProps = {}
  let className = undefined

  switch (buttonType) {
    case 'action-black-light':
      basicButtonProps = {
        color: Colors.white,
        backgroundColor: Colors.blackLight,
        padding: '12px 35px 11px !important',
        hover: { backgroundColor: Colors.black, ...hover },
      }
      break
    case 'primary':
      className = 'custom-primary'
      basicButtonProps = {
        backgroundColor: Colors.cornflowerBlue,
        color: Colors.white,
        hover: {
          backgroundColor: Colors.chambray,
          ...hover,
        },
        focus: {
          ...focus,
        },
      }
      break
    case 'secondary':
      className = 'custom-secondary'
      basicButtonProps = {
        backgroundColor: Colors.anakiwa,
        color: Colors.chambray,
        hover: {
          backgroundColor: Colors.cornflowerBlue,
          color: Colors.white,
          ...hover,
        },
      }
      break
    case 'tertiary':
      // className = 'custom-tertiary'
      // this is not a mistake we have a different set up so this works best for us
      className = 'custom-secondary'
      basicButtonProps = {
        backgroundColor: 'transparent',
        color: Colors.cornflowerBlue,
        border: `solid 1px ${Colors.cornflowerBlue}`,
        hover: {
          backgroundColor: Colors.zumthor,
          color: Colors.cornflowerBlue,
          ...hover,
        },
      }
      break
    case 'destructive':
      basicButtonProps = {
        backgroundColor: Colors.outrageousOrange,
        color: Colors.white,
        hover: {
          backgroundColor: Colors.alizarinCrimson,
          ...hover,
        },
      }
      break
    case 'text':
      basicButtonProps = {
        fontWeight: 700,
        color: Colors.cornflowerBlue,
        backgroundColor: 'transparent',
        border: 'none',
        padding: '0px',
        hover: {
          color: Colors.chambray,
          ...hover,
        },
        disable: {
          backgroundColor: 'transparent',
          ...disable,
        },
      }
      break
    case 'basic':
      basicButtonProps = {
        color: Colors.cornflowerBlue,
        backgroundColor: 'transparent',
        border: 'none',
        padding: '0px 8px',
        hover: {
          backgroundColor: Colors.zumthor,
          color: Colors.cornflowerBlue,
          ...hover,
        },
      }
      break
  }
  basicButtonProps = { ...basicButtonProps, fontSize, height }

  if (!basicButtonProps.padding) basicButtonProps.padding = title ? '0 16px' : '0 8px'

  return (
    <BasicButton className={className} type={type} {...basicButtonProps} {...props} onClick={clicked}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 8,
          whiteSpace: 'nowrap',
        }}
      >
        {image !== null && imagePosition === 'left' && image}
        {title}
        {image !== null && imagePosition === 'right' && image}
      </div>
    </BasicButton>
  )
}

export interface ThreeDotMenuButtonProps extends BasicButtonProps {
  iconColor?: string
  iconSize?: number
}

export const ThreeDotMenuButton: React.FC<ThreeDotMenuButtonProps> = ({
  iconColor = Colors.cottonSeed,
  iconSize = 25,
  hover = { backgroundColor: Colors.concrete },
  ...props
}) => {
  return <DefaultButton hover={hover} image={<MoreHorizontal color={iconColor} size={iconSize} />} {...props} />
}

interface FormActionButtonsProps {
  submitButtonLabel?: string
  secondaryButtonLabel?: string
  secondaryButtonType?: buttonType
  primaryButtonType?: buttonType
  onCancel?(): any
  onDelete?(): any
  handlePrimaryButton?(): any
  primaryDisabled?: boolean
  confirmDeleteText?: string
  deleteButtonLabel?: string
  primaryCentered?: boolean
}

export class FormActionButtons extends React.Component<FormActionButtonsProps> {
  render() {
    const {
      onCancel,
      onDelete,
      submitButtonLabel,
      secondaryButtonLabel,
      secondaryButtonType,
      handlePrimaryButton,
      primaryDisabled,
      primaryButtonType,
      confirmDeleteText,
      deleteButtonLabel,
    } = this.props

    const handleClickRemove = () => {
      sharedAppStateStore.sharedConfirmDialogProps = {
        onConfirm: onDelete,
        title: confirmDeleteText,
        confirmButtonTitle: 'Delete',
      }
    }

    return (
      <Row align="middle" style={{ marginTop: '24px' }}>
        <Col span="24">
          {onDelete && (
            <DefaultButton title={deleteButtonLabel || 'Remove'} buttonType="destructive" clicked={handleClickRemove} />
          )}
          <DefaultButton
            style={{ float: 'right', marginLeft: '5px' }}
            title={submitButtonLabel || 'Save'}
            type="submit"
            buttonType={primaryButtonType || 'primary'}
            disabled={primaryDisabled}
            clicked={handlePrimaryButton}
          />
          {onCancel && (
            <DefaultButton
              style={{ float: 'right', marginLeft: '5px' }}
              title={secondaryButtonLabel || 'Cancel'}
              buttonType={secondaryButtonType || 'tertiary'}
              clicked={onCancel}
            />
          )}
        </Col>
      </Row>
    )
  }
}

interface ReactButtonProps {
  reacted: boolean
  reactionType: 'like' | 'thumbsUp' | 'thumbsDown'
  width?: string
  height?: string
  tooltipTitle?: string
}

export const ReactButton: React.FC<ReactButtonProps & ButtonProps> = (props) => {
  const { tooltipTitle, reacted, reactionType, width, height } = props
  const isMobile = useMediaQuery(mobileQuery)
  let reactedImage = undefined
  let defaultImage = undefined
  let defaultTooltipTitle = undefined
  const imgWidth = width || (isMobile ? '24px' : '29px')
  const imgHeight = height || (isMobile ? '24px' : '29px')
  switch (reactionType) {
    case 'like':
      defaultTooltipTitle = 'Give Applause'
      reactedImage = <img src={ClapFilled} alt="Like" width={imgWidth} height={imgHeight} />
      defaultImage = <img src={ClapOutlined} alt="Like" width={imgWidth} height={imgHeight} />
      break
    case 'thumbsUp':
      defaultTooltipTitle = 'Like'
      reactedImage = <ThumbsUp size={imgWidth} fill={Colors.portage} />
      defaultImage = <ThumbsUp size={imgWidth} />
      break
    case 'thumbsDown':
      defaultTooltipTitle = 'Dislike'
      reactedImage = <ThumbsDown size={imgWidth} fill={Colors.portage} />
      defaultImage = <ThumbsDown size={imgWidth} />
      break
  }
  return (
    <Tooltip title={tooltipTitle || defaultTooltipTitle}>
      <DefaultButton {...props} image={reacted ? reactedImage : defaultImage} />
    </Tooltip>
  )
}

interface FavouriteButtonProps {
  favourited: boolean
  accessRole: AccessRole
  width?: string
  height?: string
}

export const FavouriteButton: React.FC<FavouriteButtonProps & ButtonProps & { dark?: boolean }> = (props) => {
  const isMobile = useMediaQuery(mobileQuery)
  if (sharedDataStore.user.isFaasPublic()) return null
  const { favourited, width, height, accessRole } = props
  const imgWidth = width || (isMobile ? '20px' : '24px')
  const imgHeight = height || (isMobile ? '20px' : '24px')
  let starredImage = <img src={StarFilled} alt="star" width={imgWidth} height={imgHeight} />
  let defaultImage = (
    <img
      src={StarEmpty}
      alt="star"
      width={imgWidth}
      height={imgHeight}
      style={props.dark ? { filter: 'brightness(0) invert(1)' } : null}
    />
  )

  const prefix = favourited ? 'Remove from' : 'Add to'
  const copy = accessRole === 'manager' ? "Manager's Picks" : 'Favorites'

  return (
    <Tooltip title={`${prefix} ${copy}`}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* This div is needed to make the tooltip appear over the button */}
        <DefaultButton
          {...props}
          image={favourited ? starredImage : defaultImage}
          clicked={(e) => {
            if (props.clicked) {
              props.clicked(e)
              showNotification({ message: favourited ? `Removed from ${copy}` : `Added to ${copy}` })
            }
          }}
        />
      </div>
    </Tooltip>
  )
}
interface IConnectOAuthButtonProps {
  authorizeURI: string
  clientID: string
  company: string
  connected: boolean
  image: any
  redirectURI: string
  scope?: string
}

const ConnectOAuthButton: React.FC<IConnectOAuthButtonProps> = ({
  authorizeURI,
  clientID,
  company,
  connected,
  image,
  redirectURI,
  scope,
}) => {
  const redirect = () => {
    let authorizeURL = `${authorizeURI}?client_id=${clientID}&redirect_uri=${redirectURI}&response_type=code`
    if (scope) {
      authorizeURL = `${authorizeURL}&scope=${scope}`
    }
    window.open(`${authorizeURL}`, '_self')
  }
  return (
    <DefaultButton
      clicked={redirect}
      disabled={connected}
      style={{ float: 'left', marginTop: '5px' }}
      buttonType="primary"
      title={connected ? `${company} Connected` : `Connect ${company}`}
      image={image}
    />
  )
}

interface IConnectButtonProps {
  connected: boolean
}

export const ConnectGongButton: React.FC<IConnectButtonProps> = ({ connected }) => {
  return (
    <ConnectOAuthButton
      authorizeURI="https://app.gong.io/oauth2/authorize"
      clientID={GONG_CLIENT_ID}
      company="Gong"
      connected={connected}
      image={<img height="16px" src={GongIcon} alt="Gong logo" />}
      redirectURI={Paths.getGongOAuthRedirectURL()}
    />
  )
}

export const ConnectZoomButton: React.FC<IConnectButtonProps> = ({ connected }) => {
  return (
    <ConnectOAuthButton
      authorizeURI="https://zoom.us/oauth/authorize"
      clientID={process.env.REACT_APP_ZOOM_CLIENT_ID}
      company="Zoom"
      connected={connected}
      image={<img height="16px" src={ZoomIcon} alt="Zoom logo" />}
      redirectURI={Paths.getZoomOAuthRedirectURL()}
    />
  )
}

export const ConnectSalesforceButton: React.FC<IConnectButtonProps> = ({ connected }) => {
  return (
    <ConnectOAuthButton
      authorizeURI="https://login.salesforce.com/services/oauth2/authorize"
      clientID={SALESFORCE_CLIENT_ID}
      company="Salesforce"
      connected={connected}
      image={<img height="16px" src={SalesforceIcon} alt="Salesforce logo" />}
      redirectURI={Paths.getSalesforceOAuthRedirectURL()}
      scope="api+refresh_token"
    />
  )
}

export const ConnectOutreachButton: React.FC<IConnectButtonProps> = ({ connected }) => {
  return (
    <ConnectOAuthButton
      authorizeURI="https://api.outreach.io/oauth/authorize"
      clientID={OUTREACH_CLIENT_ID}
      company="Outreach"
      connected={connected}
      image={<img height="16px" src={OutreachIcon} alt="Outreach logo" />}
      redirectURI={Paths.getOutreachOAuthRedirectURL()}
      scope="prospects.read+users.read+opportunities.read+tasks.read+mailings.read+calls.read"
    />
  )
}

export const VideoRecorderButton = styled.button`
  height: ${({ style }) => (style && style.height) || '65px'};
  width: ${({ style }) => (style && style.width) || '65px'};
  border-radius: ${({ style }) => (style && style.borderRadius) || '32.5px'};
  background-color: ${Colors.magnolia};
  text-align: center;
  border: none;
  cursor: pointer;
  margin: 5px;
  pointer-events: all;

  :hover {
    background-color: ${Colors.hawkesBlue};
  }
  :hover:disabled {
    cursor: not-allowed;
    background-color: ${Colors.magnolia};
  }
`

interface DeleteButtonProps {
  onClick: () => void
  color?: string
  fontSize?: number
  type?: 'filled' | 'outline'
  padding?: string
  height?: string
  disabled?: boolean
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({
  type = 'outline',
  onClick,
  color = Colors.cinnabar,
  fontSize = 19,
  padding = '0 8px',
  height = '40px',
  disabled = false,
}) => {
  const props = { color, size: fontSize }
  return (
    <Tooltip title="Delete">
      <DefaultButton
        clicked={onClick}
        image={type === 'outline' ? <Trash {...props} /> : <Trash2 {...props} />}
        padding={padding}
        height={height}
        disabled={disabled}
      />
    </Tooltip>
  )
}

type EditButtonProps = {
  obj: EditableElement
  color?: string
  onClick?: (obj: EditableElement) => void
  fontSize?: number
}

export const EditButton: React.FC<EditButtonProps> = ({ obj, color = Colors.white, onClick, fontSize = 24 }) => {
  const handleOnClick = (e: React.MouseEvent) => {
    e.preventDefault()
    if (onClick) onClick(obj)
    else if (!(obj instanceof Rubric) && !(obj instanceof Template)) {
      sharedAppStateStore.navigate(getFeedContentTargetLink(obj, { isAuthoring: true }))
    }
  }

  if (!obj.canEdit()) return null

  return (
    <Tooltip title="Edit">
      <DefaultButton clicked={handleOnClick} image={<Edit3 color={color} size={fontSize} />} />
    </Tooltip>
  )
}

type AddToQueueButtonProps = {
  obj: QueueElement
  color?: string
  insideQueue?: boolean
  size?: 'small' | 'medium'
  style?: React.CSSProperties
}

export const AddToQueueButton: React.FC<AddToQueueButtonProps> = ({
  obj,
  color = Colors.white,
  insideQueue,
  size = 'medium',
  style,
}) => {
  if (sharedDataStore.user.isFaasPublic()) return null
  const isQueueItem = obj instanceof QueueItem
  const userId = isQueueItem ? obj.userId : sharedDataStore.user.id
  const title = getFeedContentTitle(obj)

  const addToQueue = async () => {
    try {
      await QueueItem.addToQueue(obj, [userId])

      showNotification({
        message: `${title ? `${title} has been ` : ''} added to your Tasks`,
      })
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  const hideAddToQueueModal = () => (sharedAppStateStore.addToQueueModalProps = undefined)

  const handleOpenAddToQueueModal = () => {
    sharedAppStateStore.addToQueueModalProps = {
      onSuccess: hideAddToQueueModal,
      item: obj,
      onCancel: hideAddToQueueModal,
    }
  }

  const removeFromQueue = async () => {
    try {
      await QueueItem.removeFromQueue(obj, [userId])

      const completed = isQueueItem ? obj.linkedObject.progress === 1 : false
      sharedDataStore.refreshQueue({ user_id: userId, completed: completed })

      showNotification({
        message: `${title ? `${title} has been ` : ''} removed from your Tasks!`,
      })
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  const handleOnClick = (e: React.MouseEvent) => {
    e.preventDefault()
    if (insideQueue) {
      removeFromQueue()
    } else {
      if (sharedDataStore.user.isFaasAdminOrManager()) {
        handleOpenAddToQueueModal()
      } else {
        addToQueue()
      }
    }
  }

  return (
    <Tooltip title={insideQueue ? 'Remove from Tasks' : 'Add to Tasks'}>
      <DefaultButton
        size={size}
        clicked={handleOnClick}
        style={{ width: '100%', ...style }}
        image={<List color={color} size={size === 'small' ? '16px' : '24px'} />}
      />
    </Tooltip>
  )
}

type UploadThumbnailButtonProps = FormikFieldProps & {
  handleThumbnailChange?: (fileUrl: string) => void
}

export const UploadThumbnailButton: React.FC<UploadThumbnailButtonProps> = ({
  handleThumbnailChange,
  name,
  validate,
  fast,
  ...restProps
}) => {
  return (
    <Field name={name} validate={validate} fast={fast}>
      {({ field: { value }, form: { setFieldValue, setFieldTouched } }: FieldProps) => {
        const handleUpload = async (file: File) => {
          try {
            const fileUrl = await sharedAppStateStore.wrapAppLoading(uploadToS3(file), 'Uploading thumbnail...')
            setFieldValue(name, fileUrl)
            handleThumbnailChange?.(fileUrl)
          } catch (err) {
            sharedAppStateStore.handleError(err)
          } finally {
            return false
          }
        }

        const handleDelete = () => {
          setFieldValue(name, null)
          handleThumbnailChange?.(null)
        }
        return (
          <Space align="start">
            <Upload
              accept="image/png, image/jpeg, image/gif"
              name="avatar"
              beforeUpload={handleUpload}
              showUploadList={false}
            >
              <DefaultButton buttonType="tertiary" title={value ? 'Replace Thumbnail' : 'Upload Thumbnail'} />
            </Upload>
            {value ? (
              <>
                <Image width={150} src={value} />
                <Trash color={Colors.burntSienna} size={16} onClick={handleDelete} />
              </>
            ) : null}
          </Space>
        )
      }}
    </Field>
  )
}

interface IGoogleDriveButtonProps extends ButtonProps {
  handleFileSelected: (doc: CallbackDoc) => void | Promise<void>
}

export const GoogleDriveButton: React.FC<IGoogleDriveButtonProps> = ({ handleFileSelected, clicked, ...props }) => {
  const [openPicker] = useDrivePicker()

  const onClick = async () => {
    const myDriveView = new google.picker.DocsView(google.picker.ViewId.DOCS).setOwnedByMe(true).setIncludeFolders(true)
    const sharedDrivesView = new google.picker.DocsView(google.picker.ViewId.DOCS)
      .setEnableDrives(true)
      .setIncludeFolders(true)
    const sharedWithMeView = new google.picker.DocsView(google.picker.ViewId.DOCS)
      .setOwnedByMe(false)
      .setIncludeFolders(true)

    const documentsView = new google.picker.DocsView(google.picker.ViewId.DOCUMENTS).setLabel('Docs')
    const presentationsView = new google.picker.DocsView(google.picker.ViewId.PRESENTATIONS)
    const spreadsheetsView = new google.picker.DocsView(google.picker.ViewId.SPREADSHEETS).setLabel('Sheets')
    const pdfsView = new google.picker.DocsView(google.picker.ViewId.PDFS)
    const imageView = new google.picker.DocsView(google.picker.ViewId.DOCS_IMAGES)
    const videosView = new google.picker.DocsView(google.picker.ViewId.DOCS_VIDEOS)

    const pickerConfig: PickerConfiguration = {
      clientId: process.env.REACT_APP_GDRIVE_CLIENT_ID,
      developerKey: process.env.REACT_APP_GDRIVE_API_KEY,
      disableDefaultView: true,
      customViews: [
        myDriveView,
        sharedDrivesView,
        sharedWithMeView,
        documentsView,
        presentationsView,
        spreadsheetsView,
        pdfsView,
        imageView,
        videosView,
      ],
      callbackFunction: (data) => {
        if (data.action !== 'picked') return
        const doc = data.docs[0]
        if (doc.lastEditedUtc) doc.lastEditedUtc = Math.round(doc.lastEditedUtc / 1000)
        handleFileSelected(doc)
      },
    }

    User.getGoogleDriveAccessToken((token: string) => {
      pickerConfig.token = token
      openPicker(pickerConfig)
    })
  }

  return (
    <DefaultButton
      id="google-drive-btn"
      title="Import from Google Drive"
      buttonType="primary"
      image={<img src={GoogleDriveLogo} alt="Google Drive Logo" height="24px" />}
      {...props}
      clicked={clicked || onClick}
    />
  )
}

export const RoundedBlueButton = styled.span`
  padding: 3px 8px;
  background-color: ${Colors.cornflowerBlue};
  color: ${Colors.white};
  border-radius: 30px;
  flex-wrap: wrap;
  cursor: pointer;
  transition: 0.2s;
  height: 30px;
  white-space: nowrap;

  :hover {
    filter: brightness(110%);
    -webkit-filter: brightness(110%);
    -moz-filter: brightness(110%);
    -o-filter: brightness(110%);
    -ms-filter: brightness(110%);
  }
`

export type CSVDataExportType = DashboardCategory | LearningContentType | TeamTab
interface ExportToCSVProps {
  buttonSize?: ButtonSizeOption
  handle: () => Promise<void>
}

@observer
export class ExportButton extends Component<ExportToCSVProps> {
  @observable loading: boolean = false

  constructor(props: ExportToCSVProps) {
    super(props)
    makeObservable(this)
  }
  handleExport = async () => {
    try {
      this.loading = true
      await this.props.handle()
    } catch (err) {
      sharedAppStateStore.handleError(err)
    } finally {
      this.loading = false
    }
  }
  render() {
    return (
      <>
        {sharedDataStore.user.isFaasAdmin() ? (
          <ContainerDiv display="flex" flexDirection="row" alignItems="center" gap="20px">
            <DefaultButton
              title={this.loading ? 'Exporting' : 'Export'}
              size={this.props.buttonSize}
              buttonType="tertiary"
              clicked={this.handleExport}
              disabled={this.loading}
              style={{ cursor: this.loading ? 'not-allowed' : 'pointer' }}
              image={
                <>
                  {this.loading ? (
                    <Loader
                      iconSize={20}
                      style={{
                        color: 'inherit',
                      }}
                    />
                  ) : (
                    <Share color={Colors.cornflowerBlue} size={'1.25rem'} />
                  )}
                </>
              }
            />
          </ContainerDiv>
        ) : null}
      </>
    )
  }
}

export const VideoRecordButton: React.FC<{
  style?: CSSProps
  disabled?: boolean
  autoStartRecord?: boolean
  showRecordVideoTitle?: boolean
  handleVideoRecordSuccess: (videoUrl: string) => void
}> = ({ style, disabled, autoStartRecord, showRecordVideoTitle, handleVideoRecordSuccess }) => {
  const [modalAutoOpened, setModalAutoOpened] = useState(false)
  const closeVideoFormModal = () => {
    sharedAppStateStore.videoModalProps = undefined
  }

  const handleVideoSuccess = (videoUrl: string) => {
    handleVideoRecordSuccess(videoUrl)
    closeVideoFormModal()
  }

  const handleOpenVideoModal = () => {
    sharedAppStateStore.videoModalProps = {
      onSuccess: handleVideoSuccess,
      onCancel: closeVideoFormModal,
    }
  }

  if (autoStartRecord && !modalAutoOpened) {
    setModalAutoOpened(true)
    handleOpenVideoModal()
  }

  return (
    <DefaultButton
      size="small"
      disabled={disabled}
      buttonType="tertiary"
      image={<Video size={16} />}
      style={{
        borderRadius: '7px',
        ...style,
      }}
      clicked={handleOpenVideoModal}
      title={showRecordVideoTitle ? 'Record' : null}
    />
  )
}

export const TemplateButton: React.FC<
  ButtonProps & {
    contentType: TemplateableLearningContentType
    handleTemplateSelected: (template: Template) => Promise<void> | void
  }
> = ({ contentType, handleTemplateSelected, ...buttonProps }) => {
  const closeTemplateModal = useCallback(() => {
    sharedAppStateStore.templateModalProps = undefined
  }, [])

  const onSuccess = useCallback(
    (template: Template) => {
      handleTemplateSelected(template)
      closeTemplateModal()
    },
    [handleTemplateSelected, closeTemplateModal]
  )

  return (
    <DefaultButton
      title="Pick Template"
      buttonType="secondary"
      size="small"
      {...buttonProps}
      clicked={() =>
        (sharedAppStateStore.templateModalProps = { contentType, onCancel: closeTemplateModal, onSuccess })
      }
    />
  )
}

export const CollapsibleMenuButton: React.FC<{ tooltipTitle?: string; collapsed?: boolean; clicked: () => void }> = ({
  tooltipTitle,
  collapsed,
  clicked,
}) => {
  const tooltip = tooltipTitle ? `${collapsed ? 'Show' : 'Hide'} ${tooltipTitle}` : ''
  return (
    <Tooltip title={tooltip}>
      <DefaultButton
        width="37px"
        clicked={clicked}
        image={<Menu size={16} />}
        style={{
          border: 'none',
          boxShadow: 'none',
          color: Colors.sandstone,
          backgroundColor: Colors.white,
        }}
      />
    </Tooltip>
  )
}

const FilterDropdownBaseButton = styled.button<{ selected?: boolean }>`
  min-height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 4px 8px 4px 12px;
  gap: 4px;
  border-radius: 20px;
  border: solid 1px ${(props) => (props.selected ? '#508BEF' : Colors.sharkOpacity10)};
  color: ${(props) => (props.selected ? '#508BEF' : '#74716F;')};
  background: ${(props) => (props.selected ? Colors.zumthor : 'white')};
`

export interface FilterDropdownButtonProps {
  title: string
  selected?: boolean
  onClick?: (e: React.MouseEvent) => void
  onClear?: () => void
}

export const FilterDropdownButton = ({ title, selected, onClick, onClear }: FilterDropdownButtonProps) => {
  const handleClear = (e: React.MouseEvent) => {
    e.stopPropagation()
    onClear()
  }
  return (
    <FilterDropdownBaseButton selected={selected} onClick={onClick} type="button" style={{ whiteSpace: 'nowrap' }}>
      <FjText color="inherit" fontSize="small">
        {title}
      </FjText>

      {selected && onClear ? <X size={16} onClick={handleClear} /> : undefined}
      <ChevronDown size={16} style={{ minWidth: '16px' }} />
    </FilterDropdownBaseButton>
  )
}

interface DropdownButtonProps extends React.PropsWithChildren {
  menu?: MenuProps
  backgroundColor?: string
  background?: string
  dropdownProps?: DropdownProps
}

export const DropdownButton: React.FC<DropdownButtonProps> = ({
  menu,
  backgroundColor,
  background,
  children,
  dropdownProps,
}) => {
  return (
    <span style={{ cursor: 'pointer' }}>
      <FjDropdown menu={menu} placement="bottomRight" {...dropdownProps}>
        <ContainerDiv
          borderRadius="8px"
          backgroundColor={backgroundColor || Colors.cornflowerBlue}
          padding="5px 14px"
          display="flex"
          alignItems="center"
          height="38px"
          background={background}
        >
          {children}
        </ContainerDiv>
      </FjDropdown>
    </span>
  )
}
