import moment from 'moment'
import Route from 'src/network/Route'
import { FlockjayProvider } from 'src/network/FlockjayProvider'
import { sharedDataStore } from 'src/store/DataStore'
import { getFeedContentData, getFeedContentTargetLink, getFeedContentType } from 'src/utils/content'
import { DataObject } from 'src/models/DataObject'
import MethodTypes from 'src/models/enums/MethodTypes'
import { FeedPost } from 'src/models/FeedPost'
import { FeedPlaylist } from 'src/models/FeedPlaylist'
import { Prompt } from 'src/models/Prompt'
import { Course } from 'src/models/Course'
import { LearningPath } from 'src/models/LearningPath'
import { Call } from 'src/models/Call'
import { Asset } from 'src/models/Asset'
import { CoachingMoment } from 'src/models/CoachingMoment'
import { SfOpportunity } from './SfOpportunity'
import { formatDateToPST } from 'src/utils/format'

export type QueueElementType =
  | 'feedpost'
  | 'playlist'
  | 'prompt'
  | 'learningpath'
  | 'course'
  | 'call'
  | 'asset'
  | 'opportunity'
  | 'coachingmoment'

export type QueueElement =
  | FeedPost
  | FeedPlaylist
  | Prompt
  | Course
  | LearningPath
  | Call
  | Asset
  | SfOpportunity
  | CoachingMoment

export class QueueItem extends DataObject {
  static OVERRIDE_MAPPINGS = {
    linkedObject: (data) => getFeedContentData(data.linkedObject, data.linkedContentType),
    toggleAllowed: (data) => sharedDataStore.user.id === data.addedBy,
  }
  static apiEndpoint: string = '/feed/queue/'
  static dueDateDisplayFormat = 'MMM Do, YYYY h:mm A'

  userId: string
  linkedContentType: QueueElementType
  linkedObject: QueueElement
  linkedObjectId: string
  addedBy?: string
  toggleAllowed: boolean
  dueDate?: moment.Moment
  createdAt: moment.Moment = moment()
  lastUpdated: moment.Moment = moment()
  sfMetadata?: SfOpportunity

  getLinkedObjectDetailPath = (isQueue?: boolean) => getFeedContentTargetLink(this.linkedObject, { isQueue })

  isAddedByUser = (userId: string) => this.addedBy === userId

  static addToQueue = async (obj: QueueElement, userIds?: string[], groupIds?: string[], dueDate?: moment.Moment) => {
    const data = { linked_object_id: obj.id, linked_content_type: getFeedContentType(obj) }

    if (userIds && userIds.length) data['user_ids'] = userIds
    if (groupIds && groupIds.length) data['group_ids'] = groupIds

    if (dueDate) data['due_date'] = formatDateToPST(dueDate)
    const res = await FlockjayProvider(new Route(MethodTypes.POST, `/feed/queue/add_to_queue/`, data))
    sharedDataStore.user.updateIncompleteQueueItemCount()
    return res
  }

  static removeFromQueue = async (obj: QueueElement, userIds: string[]) => {
    const data = { linked_object_id: obj.id, user_ids: userIds }
    const res = await FlockjayProvider(new Route(MethodTypes.POST, `/feed/queue/remove_from_queue/`, data))
    sharedDataStore.user.updateIncompleteQueueItemCount()
    return res
  }

  static sendReminderByContent = async (content_id: string) => {
    const { path } = QueueItem.formatActionEndpoint(undefined, undefined, 'notify_incomplete_users')
    return await FlockjayProvider(new Route(MethodTypes.POST, path, undefined, undefined, { content_id }))
  }

  getDueDateDisplay = () => {
    if (!this.dueDate) return ''
    return this.dueDate.format(QueueItem.dueDateDisplayFormat)
  }

  dueDatePassed = () => {
    if (!this.dueDate) return false
    return this.dueDate.diff(moment()) < 0
  }

  isOverdue = () => {
    return this.dueDatePassed() && this.linkedObject.progress < 1
  }

  canEdit = () => false

  canArchive = () => false

  canDelete = () => false

  canAddToCollection = () => false

  canAddToLearningPath = () => false

  canAddToHub = () => false

  canAddToPromotedSearch = () => false

  canDuplicate = () => false

  hasOpportunity = () => false

  hasStageName = () => false
}
