import { observable, computed, action, makeObservable, runInAction } from 'mobx'
import moment from 'moment'

import Role from './enums/Role'
import { DataObject } from 'src/models/DataObject'
import { SessionToken } from 'src/models/SessionToken'
import { Course } from 'src/models/Course'
import Route from 'src/network/Route'
import MethodTypes from 'src/models/enums/MethodTypes'
import { FlockjayProvider } from 'src/network/FlockjayProvider'
import { NoAuthProvider } from 'src/network/NoAuthProvider'
import { urlBase64ToUint8Array } from 'src/utils/format'
import { sharedDataStore } from 'src/store/DataStore'
import { Company } from 'src/models/Company'
import { QueueItem } from 'src/models/QueueItem'
import { ALL_PARTNERS_GROUP, Group } from 'src/models/Group'
import { Author } from 'src/models/Author'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { makePersistable } from 'mobx-persist-store'
import { ExternalAcademy } from 'src/models/ExternalAcademy'
import { UserCertificate } from 'src/models/UserCertificate'
import { sharedQueryClient } from 'src/store/QueryClient'

const USER_STORE_KEY = 'USER_STORE'

export const LAST_SELECTED_GROUP_ID_KEY = 'LAST_SELECTED_GROUP_ID'

export type AccessRole = 'manager' | 'admin' | 'public' | 'partner' | ''

export type AuthHandler = () => void

const serializeAndSortGroups = (groupData) =>
  groupData
    ? Group.fromData(groupData).sort((a: Group, b: Group) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    : []

export class User extends DataObject {
  static readonly EXPORT_TO_CSV_FIELDS = 'manager'
  static OVERRIDE_MAPPINGS = {
    key: (data) => data.id,
    sessionToken: (data) => (data.sessionToken ? SessionToken.fromData(data.sessionToken) : undefined),
    fullName: (data) => (data.fullName ? data.fullName.charAt(0).toUpperCase() + data.fullName.slice(1) : undefined),
    company: (data) => (data.company ? Company.fromData(data.company) : undefined),
    groups: ({ groups }) => serializeAndSortGroups(groups),
    userSpecificGroups: ({ userSpecificGroups }) => serializeAndSortGroups(userSpecificGroups),
    googleDriveAccessToken: (data: any) => {
      if (!data.googleDriveAccessToken) return
      return {
        ...data.googleDriveAccessToken,
        expiry: moment.utc(data.googleDriveAccessToken.expiry),
      }
    },
    manager: (data) => (data.manager ? Author.fromData(data.manager) : undefined),
    lastActive: ({ lastActive }) => (lastActive ? moment(lastActive) : undefined),
    academy: ({ academy }) => (academy ? ExternalAcademy.fromData(academy) : undefined),
    certificates: (data) => (data.certificates ? UserCertificate.fromData(data.certificates) : []),
  }

  static StudentOrCandidateRole = [Role.Student, Role.Candidate]
  static AdminOrPartnerRole = [Role.Partner, Role.Admin]
  static TeacherOrStudentOrCandidateRole = [Role.Teacher, Role.Student, Role.Candidate]
  static TeacherOrStudentRole = [Role.Teacher, Role.Student]
  static PartnerRole = [Role.Partner]
  static AdminRole = [Role.Admin]
  static AdminOrTeacher = [Role.Teacher, Role.Admin]

  static apiEndpoint = '/api/users/'

  key: string
  id: string
  username: string
  @observable fullName: string
  email: string
  primaryEmail: string
  phoneNumber: string
  @observable profileImgUrl: string
  companyId: string
  company: Company = new Company()
  @observable pronouns: string = ''
  @observable jobPosition: string = ''
  bio: string = ''
  linkedinId?: string
  isActive: boolean
  role: Role = Role.Student
  lastActive?: moment.Moment
  // Do not use the courses field as it shall not be returned from the BE hereon.
  courses: Course[] = []
  qualificationInterviewStatus: string
  admitStatus: 'Admitted' | 'Enrolled' | 'Accepted'
  sessionToken: SessionToken = new SessionToken('', '', moment(), moment())
  @observable unreadConversationCount: number
  @observable incompleteQueueItemCount: number
  @observable conversationCount: number
  referralCode: string
  status: string
  points?: number
  accessRole: AccessRole = ''
  feedPostsPoints?: number
  feedPostsCount?: number
  commentsPoints?: number
  commentsCount?: number
  reactionsPoints?: number
  courseCompletionPoints?: number
  courseCompletionCount?: number
  learningPathCompletionPoints?: number
  learningPathCompletionCount?: number
  @observable unreadNotificationCount: number
  @observable postsToReviewCount: number
  @observable assetsToReviewCount: number
  @observable ungradedAssignmentCount: number
  @observable favouritesCount: number
  groups: Group[] = []
  userSpecificGroups: Group[] = []
  googleDriveAccessToken?: {
    expiry: moment.Moment
    token: string
  }
  @observable hubsCount: number
  manager?: Author
  @observable academy: ExternalAcademy
  hasReports: boolean
  certificates: UserCertificate[] = []
  locationCountry: string
  locationState: string
  department: string
  organization: string
  division: string
  costCenter: string
  startDate: moment.Moment

  constructor() {
    super()
    makeObservable(this)
    // We are persisting user object in DataStore, but badgeCounts api response is not persisting well
    // The reason is that badgeCounts api is called later, and somehow mobx-persist-store doesn't handle it well.
    // It causes hub disappearing issue, so need to persist it in different store.
    // If User class has properties that can be set later, those should be persisted as well.
    makePersistable(this, {
      name: USER_STORE_KEY,
      properties: [
        'badgeApiCalled',
        'assetsToReviewCount',
        'hubsCount',
        'incompleteQueueItemCount',
        'postsToReviewCount',
        'ungradedAssignmentCount',
        'unreadNotificationCount',
      ],
      storage: window.localStorage,
    })
  }

  @computed get displayPic() {
    return this.profileImgUrl
  }

  @computed get nonPublicUserGroups() {
    return this.groups.filter((group) => !group.isAllPublicUsersGroup())
  }

  @computed get groupIds() {
    return this.groups.map((group) => group.id)
  }

  @computed get nonPublicUserGroupIds() {
    return this.nonPublicUserGroups.map(({ id }) => id)
  }

  @computed get allPublicUsersGroupId() {
    return this.groups.find((group) => group.isAllPublicUsersGroup())?.id
  }

  @computed get userSpecificGroupIds() {
    return this.userSpecificGroups.map((group) => group.id)
  }

  @computed get defaultGroup() {
    if (this.isFaasPartner()) return this.groups.find((group) => group.name === ALL_PARTNERS_GROUP)
    return this.groups.find((group) => group.id === this.company.defaultGroupId)
  }

  @computed get showFeatureDisabledForOrg() {
    return (process.env.REACT_APP_SHOW_FEATURE_DISABLED_FOR_ORG?.split(',') ?? []).includes(this.company.name)
  }

  @action decrementUnreadMessageCount = () => {
    this.unreadConversationCount -= 1
  }

  @action decreaseUnreadNotificationsCount = () => {
    if (this.unreadNotificationCount > 0) {
      this.unreadNotificationCount -= 1
    }
  }
  @action increaseUnreadNotificationsCount = () => {
    this.unreadNotificationCount += 1
  }
  @action markAllNotificationsAsRead = () => {
    this.unreadNotificationCount = 0
  }

  getDefaultManagerId() {
    return this.isFaasAdminOrManagerWithReports() ? this.id : null
  }

  getDefaultManager() {
    return this.isFaasAdminOrManagerWithReports() ? this : null
  }
  /**
   * Both getAccessibleGroup and getFirstNonDefaultGroupIfExists methods are used to retrieve the relevant group ID.
   * getAccessibleGroup includes an additional validation step to check if the user has access to the specified groupId.
   * For example, getAccessibleGroup is specifically used to check if the groupId provided in the URL is valid and accessible to the user.
   */
  getAccessibleGroup = (groupId?: string, limitToGroupIds?: string[]) => {
    if (groupId && this.groupIds.includes(groupId) && (!limitToGroupIds || limitToGroupIds.includes(groupId)))
      return this.groups.find((group) => group.id === groupId)

    return this.getFirstNonDefaultGroupIfExists(limitToGroupIds)
  }

  getFirstNonDefaultGroupIfExists = (limitToGroupIds?: string[]) => {
    if (!this.isPartOfManyGroups()) return this.defaultGroup
    const lastSelectedGroupId = localStorage.getItem(LAST_SELECTED_GROUP_ID_KEY)
    if (
      lastSelectedGroupId &&
      this.groupIds.includes(lastSelectedGroupId) &&
      (!limitToGroupIds || limitToGroupIds.includes(lastSelectedGroupId))
    )
      return this.groups.find((group) => group.id === lastSelectedGroupId)

    let userCreatedGroups = this.groups.filter(
      (group) => !(group.isAllCompanyGroup() || group.isAllPartnersGroup() || group.isAllPublicUsersGroup())
    )
    if (limitToGroupIds) userCreatedGroups = userCreatedGroups.filter((group) => limitToGroupIds.includes(group.id))

    let targetGroups: Group[] = userCreatedGroups.length
      ? userCreatedGroups
      : limitToGroupIds
      ? this.groups.filter((group) => limitToGroupIds.includes(group.id))
      : this.groups.filter((group) => !group.isAllCompanyGroup())

    return targetGroups?.[0]
  }

  getFirstName = () => {
    return this.fullName && this.fullName.split(' ')[0]
  }

  getLastName = () => {
    return this.fullName && this.fullName.split(' ')[1]
  }

  getFirstNameAndLastInitial = () =>
    this.getLastName() ? `${this.getFirstName()} ${this.getLastName()[0]}.` : this.getFirstName()

  getInitials = () => {
    const firstName = this.getFirstName() || ''
    const lastName = this.getLastName() || ''
    return (firstName[0] || '') + (lastName[0] || '')
  }

  // fixme: this is a temporary solution for a demo feature. we should have a more robust way of figuring this out
  isAnonymous = () => !this.id || sharedDataStore.authState === 'unauthorized'

  isFaasAdmin = () => this.accessRole === 'admin'

  isFaasManager = () => this.accessRole === 'manager'

  isFaasAdminOrManagerWithReports = () => this.isFaasAdminOrManager() && this.hasReports

  isFaasPublic = () => this.isFaasUser() && this.accessRole === 'public'

  isFaasPartner = () => this.isFaasUser() && this.accessRole === 'partner'

  isFaasPublicOrPartner = () => this.isFaasPublic() || this.isFaasPartner()

  isFaasAdminOrManager = () => this.isFaasAdmin() || this.isFaasManager()

  isFaasAdminOrManagerOrPartner = () => this.isFaasAdminOrManager() || this.isFaasPartner()

  isFaasAdminOrPartner = () => this.isFaasAdmin() || this.isFaasPartner()

  isAdmin = () => this.role === Role.Admin

  isCandidate = () => this.role === Role.Candidate

  isPartner = () => this.role === Role.Partner

  isStudent = () => this.role === Role.Student

  isTeacher = () => this.role === Role.Teacher

  isFaasUser = () => this.role === Role.Faas

  isTrainerOrAdmin = () => this.role === Role.Teacher || this.role === Role.Admin

  isPartOfManyGroups = (excludePublicUserGroup = false) =>
    excludePublicUserGroup ? this.nonPublicUserGroups.length > 1 : this.groups.length > 1

  hasFaasAccess = () => [Role.Admin, Role.Faas].includes(this.role)

  hasFlockjayEmail = () => (this.email || '').endsWith('@flockjay.com')

  updateIncompleteQueueItemCount = async () => {
    try {
      const res = await QueueItem.list({ user_id: this.id, completed: false })
      this.incompleteQueueItemCount = res.count
    } catch (err) {
      sharedAppStateStore.handleError(err, undefined, false)
    }
  }

  sfdcIntegrationEnabled = () => {
    const ids = (process.env.REACT_APP_TRIAL_COMPANY_IDS || '').split(',')
    return !!this.company.integratedSalesforceOrganizationUrl && !ids.includes(this.company.id)
  }

  isMilestonesEnabled = () => (process.env.REACT_APP_MILESTONE_COMPANIES || '').split(',').includes(this.company.name)

  initalizePendo = () => {
    pendo.initialize({
      visitor: {
        id: this.id,
        email: this.email,
        fullName: this.fullName,
        role: this.role,
      },
      account: {
        id: this.id,
        username: this.username,
        companyName: this.company.name,
        active: true,
        phoneNumber: this.phoneNumber,
      },
    })
  }

  static initGoogleDriveOAuthFlow = (callback: (token: string) => void) => {
    const scopes = process.env.REACT_APP_GDRIVE_SCOPES.split(',')
      .map((path) => `https://www.googleapis.com/${path}`)
      .join(' ')

    // @ts-ignore -- react-google-drive-picker creates a global variable
    const client = google.accounts.oauth2.initCodeClient({
      client_id: process.env.REACT_APP_GDRIVE_CLIENT_ID,
      ux_mode: 'popup',
      scope: scopes,
      // Because we use `popup` there is no redirect uri, so have to pass `postmessage` instead.
      // Ref: https://stackoverflow.com/questions/55222501/google-oauth-redirect-uri-mismatch-when-exchanging-one-time-code-for-refresh-tok?rq=1
      redirect_uri: 'postmessage',
      callback: async (res: any) => {
        try {
          const { data } = await sharedAppStateStore.wrapAppLoading(
            User.connectGoogle({ code: res.code }),
            'Loading google drive...'
          )
          sharedDataStore.user = User.fromData(data)
          callback(sharedDataStore.user.googleDriveAccessToken.token)
        } catch (err) {
          sharedAppStateStore.handleError(err)
        }
      },
    })

    client.requestCode()
  }

  static refreshGoogleDriveAccessToken = async () => {
    return FlockjayProvider(new Route(MethodTypes.GET, `${User.apiEndpoint}refresh_google_drive_access_token/`))
  }

  static getGoogleDriveAccessToken = async (callback: (token: string) => void) => {
    const { googleDriveAccessToken: existingToken } = sharedDataStore.user
    if (existingToken && existingToken.expiry.isAfter(moment.utc())) {
      callback(existingToken.token)
    } else if (existingToken) {
      try {
        const { data } = await sharedAppStateStore.wrapAppLoading(
          User.refreshGoogleDriveAccessToken(),
          'Loading google drive...'
        )
        sharedDataStore.user = User.fromData(data)
        callback(sharedDataStore.user.googleDriveAccessToken.token)
      } catch (err) {
        // if refresh fails for any reason attempt to re-init oauth flow
        User.initGoogleDriveOAuthFlow(callback)
      }
    } else {
      User.initGoogleDriveOAuthFlow(callback)
    }
  }

  saveProfileImg = async (profileImgUrl: string) => {
    const { data } = await FlockjayProvider(
      new Route(MethodTypes.PATCH, User.formatActionEndpoint(this.id).path, { profileImgUrl })
    )
    this.profileImgUrl = data.profileImgUrl
  }

  deleteProfileImg = async () => {
    await FlockjayProvider(
      new Route(MethodTypes.PATCH, User.formatActionEndpoint(this.id).path, { profileImgUrl: null })
    )
    this.profileImgUrl = undefined
  }

  static connectLinkedin(data: { code: string; redirectUri: string }) {
    return FlockjayProvider(new Route(MethodTypes.POST, `${this.apiEndpoint}:userID/connect_linkedin/`, data))
  }

  static connectSalesforce(data: { code: string }) {
    return FlockjayProvider(new Route(MethodTypes.POST, `${this.apiEndpoint}:userID/connect_salesforce/`, data))
  }

  static connectGong(data: { code: string }) {
    return FlockjayProvider(new Route(MethodTypes.POST, `${this.apiEndpoint}:userID/connect_gong/`, data))
  }

  static connectZoom(data: { code: string }) {
    return FlockjayProvider(new Route(MethodTypes.POST, `${this.apiEndpoint}:userID/connect_zoom/`, data))
  }

  static connectOutreach(data: { code: string }) {
    return FlockjayProvider(new Route(MethodTypes.POST, `${this.apiEndpoint}:userID/connect_outreach/`, data))
  }

  static connectGoogle(data: { code: string }) {
    return FlockjayProvider(new Route(MethodTypes.POST, `${this.apiEndpoint}connect_google/`, data))
  }

  static postReferralFollowUp(data: { appId: string }) {
    return FlockjayProvider(new Route(MethodTypes.POST, `${this.apiEndpoint}referral_follow_up/`, data))
  }

  static login(credentials: { email: string; password: string; isLogin: boolean }) {
    return NoAuthProvider(new Route(MethodTypes.POST, '/api/auth/login/', credentials))
  }

  static refreshSession(refreshToken: string) {
    return NoAuthProvider(
      new Route(MethodTypes.POST, '/api/auth/token/refresh/', {
        refresh: refreshToken,
      })
    )
  }

  static updateWithProfile = (profile: User) => {
    sharedDataStore.user.fullName = profile.fullName
    sharedDataStore.user.profileImgUrl = profile.profileImgUrl
  }

  static forgotPassword(email: string) {
    return NoAuthProvider(new Route(MethodTypes.POST, '/api/auth/send-reset-password-link/', { email }))
  }

  static resetPassword(userId: string, timestamp: string, signature: string, password: string) {
    return NoAuthProvider(
      new Route(MethodTypes.POST, '/api/auth/reset-password/', {
        userId,
        timestamp,
        signature,
        password,
      })
    )
  }

  static changePassword(oldPassword: string, password: string, passwordConfirm: string) {
    return FlockjayProvider(
      new Route(MethodTypes.POST, '/api/auth/change-password/', {
        oldPassword,
        password,
        passwordConfirm,
      })
    )
  }

  static subscribeUserNotifications = async () => {
    if ('serviceWorker' in navigator) {
      try {
        const registration: ServiceWorkerRegistration = await navigator.serviceWorker.ready
        if (!registration.pushManager) {
          return
        }
        if (Notification.permission !== 'granted') {
          await Notification.requestPermission()
        }

        let subscription: PushSubscription = await registration.pushManager.getSubscription()
        if (subscription === null) {
          subscription = await registration.pushManager.subscribe({
            applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY),
            userVisibleOnly: true,
          })
        }

        const url = User.formatActionEndpoint(undefined, undefined, 'subscribe_push_notifications').path
        const data = {
          browser: navigator.userAgent.match(/(firefox|msie|chrome|safari|trident)/gi)[0].toLowerCase(),
          status_type: 'subscribe',
          subscription: subscription.toJSON(),
          user_agent: navigator.userAgent,
        }

        await FlockjayProvider(new Route(MethodTypes.POST, url, data))
      } catch (error) {
        sharedAppStateStore.handleError(error, undefined, false)
      }
    }
  }

  static sendManagerQueueItemReminder = async (userId: string) => {
    const { path } = User.formatActionEndpoint(userId, undefined, 'send_manager_queue_item_reminder')
    return FlockjayProvider(new Route(MethodTypes.POST, path))
  }

  @observable badgeApiCalled = false
  async refreshBadgeCounts() {
    if (sharedDataStore.authState !== 'authorized') return
    try {
      const { path } = User.formatActionEndpoint(undefined, undefined, 'badge_counts')
      const fetch = async () => {
        const { data } = await FlockjayProvider(new Route(MethodTypes.GET, path))
        return data
      }

      const data = await sharedQueryClient.fetchQuery({
        queryKey: ['badge_counts'],
        queryFn: fetch,
      })

      runInAction(() => {
        for (const [key, value] of Object.entries(data)) {
          if (typeof this[key] === 'function') continue
          this[key] = value
        }
      })
    } catch (err) {
      sharedAppStateStore.handleError(err, undefined, false)
    } finally {
      runInAction(() => {
        this.badgeApiCalled = true
      })
    }
  }

  formatWorkInfo = () => {
    if (this.company && this.jobPosition) return `${this.jobPosition} at ${this.company.name}`
    else if (this.company) return this.company.name
    else if (this.jobPosition) return this.jobPosition
    return ''
  }
}
