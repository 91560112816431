import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ArrowLeft, ArrowRight } from 'react-feather'
import { ContainerDiv, DefaultButton, FjProgress, FjText } from 'src/components/Common'
import { TaskModal } from 'src/components/Learn/TaskModal'
import { Colors } from 'src/constants/colors'
import { FeedTask } from 'src/models/FeedTask'
import { LearningPath } from 'src/models/LearningPath'
import { APIProvider } from 'src/network/APIProvider'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { sharedScrollStore } from 'src/store/ScrollStore'
import { ContentTypeIcon, fetchContentById, getFeedContentData, getFeedContentTargetLink } from 'src/utils/content'
import { useLocation, useHistory } from 'react-router-dom'
import { sharedDataStore } from 'src/store/DataStore'
import { sharedQueryClient } from 'src/store/QueryClient'
import { Tooltip } from 'antd'
import { useStickyBottom } from 'src/hooks/useStickyBottom'
import { Milestone } from 'src/models/Milestone'
import { ViewMilestoneModal } from 'src/components/Feed/MilestoneModals'

export const LearningPathNavigation = observer(() => {
  const { stickyBottom } = useStickyBottom()
  const { search } = useLocation()
  const history = useHistory()
  const [learningPath, setLearningPath] = useState<LearningPath>()
  const abortControllerRef = useRef<AbortController>()

  // @ts-ignore
  const lastLearningPathRouteIndex = sharedAppStateStore.routes.findLastIndex(
    (route) => route.contentType === 'learningpath'
  )
  const { selectedLearningPathContentId } = sharedAppStateStore.routes[lastLearningPathRouteIndex] || {}
  const currentIndex = learningPath?.learningPathContents.findIndex(
    (content) => content.contentData.id === selectedLearningPathContentId
  )
  const currentLearningContent = learningPath?.learningPathContents?.[currentIndex]

  const fetchLearningPath = useCallback(async () => {
    try {
      const { path } = sharedAppStateStore.routes[lastLearningPathRouteIndex] || {}
      if (!path) return
      abortControllerRef.current?.abort()
      abortControllerRef.current = new AbortController()
      const data = await fetchContentById(path.split('/')[2], 'learningpath', undefined, abortControllerRef.current)
      setLearningPath(data)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }, [lastLearningPathRouteIndex])

  useEffect(() => {
    sharedScrollStore.scrollToTop()
  }, [search])

  useEffect(() => {
    fetchLearningPath()
  }, [fetchLearningPath])

  useEffect(() => {
    return history.listen(() => {
      if (history.action === 'POP') {
        // Close modals when click Back/Forward button
        sharedAppStateStore.modalProps = undefined
        sharedAppStateStore.learningPathContentModalProps = undefined
      }
    })
  }, [history])

  useEffect(() => {
    sharedAppStateStore.learningPathContentModalProps = undefined
    if (learningPath && learningPath.shouldOpenConfettiModal()) {
      sharedAppStateStore.confettiModalProps = {
        obj: learningPath,
      }
    }
  }, [learningPath])

  const updateContextLearningPathProgess = useCallback(async () => {
    if (!learningPath || !currentLearningContent || currentLearningContent.contentData.progress === 1) return

    const data = await learningPath.patch('update_progress', undefined, false)
    const newlearningPath = LearningPath.fromData(data)
    if (
      newlearningPath.learningPathContents[currentIndex].contentData.progress === 1 ||
      newlearningPath.progress !== learningPath.progress
    ) {
      await sharedQueryClient.invalidateQueries({ queryKey: [LearningPath.apiEndpoint], refetchType: 'none' })
    }
    setLearningPath(() => newlearningPath)
  }, [currentIndex, currentLearningContent, learningPath])

  useEffect(() => {
    sharedDataStore.updateContextLearningPathProgess = updateContextLearningPathProgess
    return () => {
      sharedDataStore.updateContextLearningPathProgess = undefined
    }
  }, [updateContextLearningPathProgess])

  const prevDisabled = useMemo(() => {
    return currentIndex - 1 < 0
      ? true
      : learningPath?.firstLockedContentIndex() < currentIndex - 1 && learningPath.requireSequentialCompletion
  }, [currentIndex, learningPath])

  const nextDisabled = useMemo(() => {
    return currentIndex + 1 > learningPath?.learningPathContents?.length - 1
      ? true
      : learningPath?.firstLockedContentIndex() < currentIndex + 1 && learningPath.requireSequentialCompletion
  }, [currentIndex, learningPath])

  const handleNavigation = (direction: 'next' | 'prev') => {
    let targetIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1
    const targetContent = learningPath.learningPathContents[targetIndex]
    if (targetContent.contentType === 'task') {
      sharedAppStateStore.resetRoutesToLastLearningPath(targetContent.contentData.id)
      sharedAppStateStore.modalProps = {
        title: 'Task',
        children: (
          <TaskModal
            isCompleted={targetContent.contentData.progress === 1}
            title={targetContent.contentData.title}
            handleClickComplete={() => handleClickComplete(FeedTask.fromData(targetContent))}
          />
        ),
      }
    } else if (targetContent.contentType === 'milestone') {
      sharedAppStateStore.resetRoutesToLastLearningPath(targetContent.contentData.id)
      sharedAppStateStore.modalProps = {
        title: targetContent.contentData.title,
        children: (
          <ViewMilestoneModal
            milestone={targetContent.contentData as Milestone}
            handleCheckMilestoneProgress={() => handleClickComplete(Milestone.fromData(targetContent.contentData))}
          />
        ),
      }
    } else {
      sharedAppStateStore.modalProps = undefined
      sharedAppStateStore.navigate(
        getFeedContentTargetLink(getFeedContentData(targetContent.contentData, targetContent.contentType)),
        false,
        {
          retain: true,
          selectedLearningPathContentId: targetContent.contentData.id,
        }
      )
    }
  }

  const handleClickComplete = async (content: FeedTask | Milestone) => {
    if (content instanceof FeedTask && (!content.progress || content.progress !== 1)) {
      await APIProvider.updateLearningPathContentProgress(learningPath.id, content.id)
    }
    await updateContextLearningPathProgess()
    sharedAppStateStore.modalProps = undefined
  }

  const handleViewContents = () => {
    // Hide LearningPathContentModal if it's already opened
    if (sharedAppStateStore.learningPathContentModalProps) {
      sharedAppStateStore.learningPathContentModalProps = undefined
      return
    }
    sharedAppStateStore.learningPathContentModalProps = {
      learningPath,
      handleLearningPathUpdate: () => updateContextLearningPathProgess(),
      selectedContentIndex: currentIndex,
      stickyBottom,
    }
  }

  if (!sharedAppStateStore.isNavigatingLearningPath || !learningPath) return null

  const prevColor = prevDisabled ? Colors.cottonSeed : 'white'
  const nextColor = nextDisabled ? Colors.cottonSeed : 'white'

  const { learningPathRouteHistory } = sharedAppStateStore
  return (
    <ContainerDiv
      zIndex={999}
      position="fixed"
      bottom={stickyBottom}
      left={sharedAppStateStore.isMobile ? 0 : 80}
      right={0}
      backgroundColor={Colors.chambray}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="16px 32px"
      flexWrap="wrap"
    >
      <ContainerDiv
        display="flex"
        alignItems="center"
        gap={12}
        margin={sharedAppStateStore.isMobile ? 'auto' : undefined}
      >
        <ContentTypeIcon contentType="learningpath" size={24} color="white" />
        <ContainerDiv display="flex" alignItems="center">
          {learningPathRouteHistory.map((route, i) => {
            const isLast = learningPathRouteHistory.length - 1 === i
            return (
              <>
                <Tooltip placement="top" title={route.breadcrumbName} key={JSON.stringify(route)}>
                  <ContainerDiv padding="0 4.5px" maxWidth={isLast ? undefined : '133px'}>
                    <FjText
                      textAlign="left"
                      fontSize="20px"
                      fontWeight="bold"
                      color={isLast ? Colors.white : Colors.mercury}
                      rows={1}
                      cursor="pointer"
                      wordBreak="break-all"
                      onClick={() => sharedAppStateStore.navigate(route.path, false, { fromBreadcrumb: true })}
                    >
                      {route.breadcrumbName}
                    </FjText>
                  </ContainerDiv>
                </Tooltip>
                {i < learningPathRouteHistory.length - 1 && (
                  <FjText fontSize="36px" color={Colors.mercury} style={{ fontWeight: 400 }}>
                    /
                  </FjText>
                )}
              </>
            )
          })}
        </ContainerDiv>
      </ContainerDiv>

      <FjProgress
        height="12px"
        style={{ maxWidth: '200px', marginLeft: 'auto', marginRight: sharedAppStateStore.isMobile ? 'auto' : '48px' }}
        percent={Math.floor(learningPath.progress * 100)}
        infoTextColor="white"
      />
      <ContainerDiv
        display="flex"
        alignItems="center"
        gap={18}
        margin={sharedAppStateStore.isMobile ? 'auto' : undefined}
      >
        <DefaultButton
          disabled={prevDisabled}
          buttonType="text"
          title={
            <FjText textAlign="left" fontWeight="bold" color={prevColor}>
              Previous
            </FjText>
          }
          image={<ArrowLeft size={16} color={prevColor} />}
          imagePosition="left"
          clicked={() => handleNavigation('prev')}
        />
        <DefaultButton
          id="lp-nav-view-contents-btn"
          buttonType="primary"
          title="View Contents"
          clicked={handleViewContents}
          hover={{ backgroundColor: Colors.midnightBlue }}
          focus={{ backgroundColor: Colors.cornflowerBlue }}
        />
        <DefaultButton
          disabled={nextDisabled}
          buttonType="text"
          title={
            <FjText textAlign="left" fontWeight="bold" color={nextColor}>
              Next
            </FjText>
          }
          image={<ArrowRight size={16} color={nextColor} />}
          imagePosition="right"
          clicked={() => handleNavigation('next')}
        />
      </ContainerDiv>
    </ContainerDiv>
  )
})
