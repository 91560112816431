import React, { Component } from 'react'
import { observable, makeObservable, computed, action, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { Colors } from 'src/constants/colors'
import {
  ContainerDiv,
  FjTable,
  FjProgress,
  FjText,
  DefaultButton,
  DefaultLink,
  ThreeDotMenuButton,
  FjDropdown,
} from 'src/components/Common'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { AssignmentSubmission } from 'src/models/AssignmentSubmission'
import { User } from 'src/models/User'
import { GradingStatus } from 'src/components/Feed/GradingStatus'
import { Assignment } from 'src/models/Assignment'
import { fetchCompletionData, getFeedContentType } from 'src/utils/content'
import { AuthorDisplay } from 'src/components/Common/AuthorDisplay'
import { LearningPath } from 'src/models/LearningPath'
import { Course } from 'src/models/Course'
import { FeedPlaylist } from 'src/models/FeedPlaylist'
import { Prompt } from 'src/models/Prompt'
import type { ColumnsType } from 'antd/es/table'
import { QueueItem } from 'src/models/QueueItem'
import { Select, TablePaginationConfig, Tooltip } from 'antd'
import { FilterSelect } from 'src/components/Feed/FeedPostList'
import { Bell, CheckSquare, Share } from 'react-feather'
import { showNotification } from 'src/hoc/Notification'
import { Paths } from 'src/constants/navigation'
import { GroupSelect } from 'src/components/Common/GroupSelect'
import { clearFalseyValues, pluralize } from 'src/utils/format'
import { FeedPost } from 'src/models/FeedPost'
import { ViewState, ViewWrapper } from 'src/components/Common/ViewWrapper'
import moment from 'moment'
import { debounce } from 'src/utils/debounce'
import { ContentProgressAggregate } from 'src/components/Learn/ContentProgressAggregate'
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import { APIProvider } from 'src/network/APIProvider'
import { sharedDataStore } from 'src/store/DataStore'
import { ManagerFilter } from 'src/components/Common/ManagerFilter'
import { UserFilter } from 'src/components/Common/Filter/AutoCompleteFilter'

const DEFAULT_PAGE_SIZE = 15

interface GradebookTableColumn {
  key: string
  user: User
  progress: number
  dueDate: moment.Moment
}

export interface ProgressAggregateDataType {
  total?: number
  completed?: number
  started?: number
  notStarted?: number
}
export type TableViewOption = 'all_users' | 'all_tasks'

interface GradebookTableProps {
  obj: Course | LearningPath | FeedPlaylist | Prompt | FeedPost
  initialGroupId?: string
  onGroupChange?: (groupId: string) => void
  onTableViewChange?: (tableView: TableViewOption) => void
  onManagerChange?: (managerId: string) => void
  onUserChange?: (userId: string) => void
  limitToGroupIds?: string[]
  initialTableView?: TableViewOption
  initialManagerId?: string
  initialUserId?: string
  hideTableViewFilter?: boolean
  hideGroupFilter?: boolean
  hideGradeAssessmentsButton?: boolean
  hideExportMenu?: boolean
  courseDropdown?: React.ReactNode
}

@observer
export class GradebookTable extends Component<GradebookTableProps> {
  // A map from assignment id => userId => AssignmentSubmission
  assignmentVsUserSubmissionMap = new Map<string, Map<string, AssignmentSubmission>>()
  @observable submittedAssignments: AssignmentSubmission[] = undefined
  @observable columns: ColumnsType<GradebookTableColumn> = []
  @observable dataSource: { progresses: GradebookTableColumn[]; aggregates: ProgressAggregateDataType } = {
    progresses: [],
    aggregates: {},
  }
  @observable assessments: Assignment[] = undefined
  @observable userProgressMap = new Map<string, number>()
  @observable tableView: TableViewOption = this.props.initialTableView
  @observable selectedGroupId: string = sharedDataStore.user.isFaasAdminOrManagerWithReports()
    ? undefined
    : sharedDataStore.user.getAccessibleGroup(this.props.initialGroupId, this.props.obj.groupIds).id
  @observable viewState: ViewState = 'initialLoad'
  @observable totalCount = 0
  @observable currentPage = 1
  @observable ordering = ''
  @observable selectedManagerId: string = this.props.initialManagerId || sharedDataStore.user.getDefaultManagerId()
  @observable selectedUserId: string = this.props.initialUserId

  static defaultProps = {
    initialTableView: 'all_tasks',
  }

  @computed get menu() {
    return {
      items: [
        {
          key: 'export',
          label: 'Export',
          icon: <Share color={Colors.cornflowerBlue} size={20} />,
          onClick: () =>
            sharedAppStateStore.wrapAppLoading(
              APIProvider.getAnalyticsCSVData(getFeedContentType(this.props.obj), this.props.obj.id, {
                task_assigned: this.tableView === 'all_tasks',
                group_id: this.selectedGroupId,
                manager_id: this.selectedManagerId,
                user_id: this.selectedUserId,
              })
            ),
        },
      ],
    }
  }

  constructor(props: GradebookTableProps) {
    super(props)
    makeObservable(this)
  }

  async componentDidMount() {
    this.fetchGradebookTableData()
  }

  async componentDidUpdate(prevProps: Readonly<GradebookTableProps>) {
    const gradebookTableParams = ['obj', 'initialGroupId', 'initialManagerId', 'initialUserId']
    const shouldUpdate = gradebookTableParams.some((item) => this.props[item] !== prevProps[item])
    if (shouldUpdate) {
      this.selectedGroupId = this.props.initialGroupId
      this.selectedManagerId = this.props.initialManagerId
      this.selectedUserId = this.props.initialUserId
      this.fetchGradebookTableData()
    }
  }

  @action
  setColumns = () => {
    this.columns = []
    this.columns.push({
      width: '30%',
      title: 'Name',
      dataIndex: 'user',
      key: 'user_full_name',
      render: (user: User) => (
        <DefaultLink to={Paths.getProfilePath(user.id)}>
          <AuthorDisplay
            onClick={() => {}}
            avatarSize={38}
            author={user}
            fontSize="medium"
            fontWeight="semi-bold"
            style={{ color: Colors.shark, minWidth: 70 }}
          />{' '}
        </DefaultLink>
      ),
      sorter: { multiple: 1 },
      sortDirections: ['descend', 'ascend'],
    })

    this.columns.push({
      width: '30%',
      title: 'Progress %',
      key: 'progress',
      defaultSortOrder: 'descend',
      render: (row: GradebookTableColumn) => {
        const { progress, user, dueDate } = row
        return (
          <ContainerDiv cursor="pointer" onClick={() => this.handleClickUserProgress(user)}>
            <FjProgress style={{ marginTop: '-15px' }} percent={Math.floor(progress * 100)} />
            {dueDate?.isValid() ? (
              <FjText color={Colors.rollingStone} fontStyle="italic" fontWeight="semi-bold">
                {`Due: ${dueDate.format(QueueItem.dueDateDisplayFormat)}`}
              </FjText>
            ) : null}
          </ContainerDiv>
        )
      },
      sortDirections: ['descend', 'ascend'],
      sorter: { multiple: 2 },
    })

    if (this.props.obj instanceof Course) {
      this.assessments.map((assessment) =>
        this.columns.push({
          title: assessment.title,
          dataIndex: assessment.id,
          key: assessment.id,
          render: (assignmentSubmission: AssignmentSubmission) => (
            <Tooltip
              title={
                assignmentSubmission?.attemptsCount
                  ? `${assignmentSubmission.attemptsCount} ${pluralize('attempt', assignmentSubmission.attemptsCount)}`
                  : ''
              }
            >
              <div>
                <GradingStatus assignmentSubmission={assignmentSubmission} assessment={assessment} />
              </div>
            </Tooltip>
          ),
          sorter: (a, b) => {
            return (a?.[assessment.id]?.totalScore || 0) - (b?.[assessment.id]?.totalScore || 0)
          },
        })
      )
    }
  }

  @computed get paginationSetting(): TablePaginationConfig {
    return {
      showSizeChanger: false,
      pageSize: DEFAULT_PAGE_SIZE,
      showTotal: (total: number, range: number[]) => `${range[0]}-${range[1]} of ${total} items`,
      total: this.totalCount,
      current: this.currentPage,
      simple: sharedAppStateStore.isMobile,
    }
  }

  @computed get showGroupSelector() {
    return sharedDataStore.user.isFaasAdmin() && sharedDataStore.user.isPartOfManyGroups()
  }

  @action
  fetchGradebookTableData = debounce(async () => {
    try {
      const { obj } = this.props
      this.viewState = 'loading'
      if (obj instanceof Course) {
        await this.fetchAssignments()
        await this.fetchSubmittedAssessments()
      }

      const { results, count } = await fetchCompletionData(
        obj.id,
        getFeedContentType(obj),
        clearFalseyValues({
          page: this.currentPage,
          group_id: this.showGroupSelector ? this.selectedGroupId : undefined,
          manager_id: this.selectedManagerId,
          user_id: this.selectedUserId,
          task_assigned: this.tableView === 'all_tasks',
          expand: 'status',
          ordering: this.ordering,
        })
      )
      this.setColumns()
      runInAction(() => {
        this.totalCount = count
        this.dataSource['aggregates'] = results.aggregates
        this.dataSource['progresses'] = results.progresses.map((item) => {
          const row = { ...item, key: item.user.id }
          if (obj instanceof Course) {
            this.assessments.forEach((assessment) => {
              row[assessment.id] = this.assignmentVsUserSubmissionMap.get(assessment.id)?.get(item.user.id)
            })
          }
          return row
        })
        this.viewState = 'idle'
      })
    } catch (err) {
      this.viewState = 'error'
      sharedAppStateStore.handleError(err)
    }
  })

  @action
  fetchAssignments = async () => {
    try {
      this.assessments = await Assignment.list({ course_id: this.props.obj.id })
    } catch (err) {
      if (err.response?.status === 404) this.assessments = []
      else sharedAppStateStore.handleError(err)
    }
  }

  @action
  fetchSubmittedAssessments = async () => {
    try {
      this.submittedAssignments = await AssignmentSubmission.list({
        course_id: this.props.obj.id,
        fields: 'assignment_id,user_id,attempts_count,status,id,total_score',
      })
      this.submittedAssignments.forEach((submittedAssignment) => {
        if (!this.assignmentVsUserSubmissionMap.get(submittedAssignment.assignmentId)) {
          this.assignmentVsUserSubmissionMap.set(
            submittedAssignment.assignmentId,
            new Map<string, AssignmentSubmission>()
          )
        }
        this.assignmentVsUserSubmissionMap
          .get(submittedAssignment.assignmentId)
          .set(submittedAssignment.userId, submittedAssignment)
      })
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  handleGroupSelect = (groupId: string) => {
    if (this.props.onGroupChange) {
      this.props.onGroupChange(groupId)
    } else {
      this.selectedGroupId = groupId
      this.fetchGradebookTableData()
    }
  }

  handleTableViewChange = (option: TableViewOption) => {
    this.currentPage = 1
    this.tableView = option
    this.fetchGradebookTableData()
    this.props.onTableViewChange?.(option)
  }

  sendReminderByContent = async () => {
    try {
      await QueueItem.sendReminderByContent(this.props.obj.id)
      showNotification({ message: 'Reminder was successfully sent!' })
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  handleClickUserProgress = (user: User) => {
    if (this.props.obj instanceof Course || this.props.obj instanceof LearningPath) {
      sharedAppStateStore.contentProgressDetailsModalProps = {
        user,
        obj: this.props.obj,
      }
    }
  }

  @action
  handleTableChange = (
    pagination: TablePaginationConfig,
    _filters: any,
    sorter: SorterResult<GradebookTableColumn> | SorterResult<GradebookTableColumn>[],
    extra: TableCurrentDataSource<GradebookTableColumn>
  ) => {
    if (extra.action === 'paginate') {
      this.currentPage = pagination.current
    } else if (extra.action === 'sort') {
      const sorterArray = Array.isArray(sorter) ? sorter : [sorter]
      const sortDirections: Record<string, string> = {
        ascend: '',
        descend: '-',
      }
      this.ordering = sorterArray
        .map(({ order, columnKey }) => (order ? `${sortDirections[order]}${columnKey}` : ''))
        .filter(Boolean)
        .join(',')
      this.currentPage = 1
    }
    this.fetchGradebookTableData()
  }

  @action
  handleManagerFilterChange = (managerId: string) => {
    if (this.props.onManagerChange) {
      this.props.onManagerChange(managerId)
    } else {
      this.currentPage = 1
      this.selectedManagerId = managerId
      this.fetchGradebookTableData()
    }
  }

  @action
  handleUserFilterChange = ({ userId }: { userId: string }) => {
    if (this.props.onUserChange) {
      this.props.onUserChange(userId)
    } else {
      this.currentPage = 1
      this.selectedUserId = userId
      this.fetchGradebookTableData()
    }
  }

  render() {
    return (
      <ContainerDiv display="flex" flexDirection="column" gap="10px" alignItems="center" width="100%">
        <ContainerDiv display="flex" justifyContent="space-between" alignItems="center" width="100%" flexWrap="wrap">
          <ContainerDiv display="flex" gap={10} flexWrap="wrap" alignItems="center" marginBottom="8px">
            {this.props.courseDropdown}
            {!this.props.hideGroupFilter && this.showGroupSelector ? (
              <GroupSelect
                clearable
                value={this.selectedGroupId}
                onChange={this.handleGroupSelect}
                limitToGroupIds={this.props.limitToGroupIds}
              />
            ) : null}
            {!this.props.hideTableViewFilter ? (
              <FilterSelect
                style={{ color: this.tableView ? Colors.cornflowerBlue : Colors.outerSpace }}
                value={this.tableView || ''}
                onChange={this.handleTableViewChange}
              >
                <Select.Option value="all_users">All Users</Select.Option>
                <Select.Option value="all_tasks">All Assigned Tasks</Select.Option>
              </FilterSelect>
            ) : null}
            <ManagerFilter
              clearable={sharedDataStore.user.isFaasAdmin()}
              value={this.selectedManagerId}
              onChange={this.handleManagerFilterChange}
            />
            <UserFilter
              onChange={this.handleUserFilterChange}
              queryParams={{
                group: this.selectedGroupId || this.props.limitToGroupIds.join(','),
              }}
            />
          </ContainerDiv>
          <ContainerDiv display="flex" gap={10} flexWrap="wrap" alignItems="center" marginBottom="8px">
            {this.tableView === 'all_tasks' && this.dataSource['progresses'].length > 0 ? (
              <DefaultButton
                buttonType="secondary"
                // TODO: this should be handled later, story ID: #186719373
                title={false ? 'Reminder Sent!' : 'Send Reminder'}
                clicked={this.sendReminderByContent}
                image={<Bell size={16} />}
                size="small"
              />
            ) : null}
            {!this.props.hideGradeAssessmentsButton && this.props.obj instanceof Course ? (
              <DefaultLink to={Paths.submittedAssessments}>
                <DefaultButton
                  title="Grade Assessments"
                  buttonType="secondary"
                  size="small"
                  image={<CheckSquare size={16} style={{ pointerEvents: 'none' }} />}
                />
              </DefaultLink>
            ) : null}
            {!this.props.hideExportMenu ? (
              <FjDropdown menu={this.menu} placement="bottomLeft">
                <ThreeDotMenuButton iconSize={20} />
              </FjDropdown>
            ) : null}
          </ContainerDiv>
        </ContainerDiv>
        <ContentProgressAggregate tableView={this.tableView} aggregates={this.dataSource.aggregates} />
        <ViewWrapper viewState={this.viewState} showLoader={false}>
          <FjTable
            loading={this.viewState === 'loading'}
            columns={[...this.columns]}
            dataSource={[...this.dataSource['progresses']]}
            bordered={false}
            pagination={this.paginationSetting}
            scroll={{ x: this.assessments?.length > 0 ? 1000 : '100%' }}
            style={{ width: '100%' }}
            onChange={this.handleTableChange}
          />
        </ViewWrapper>
      </ContainerDiv>
    )
  }
}
