import moment from 'moment'
import { Delimiters } from 'src/constants/delimiters'
import { FlockjayProvider } from 'src/network/FlockjayProvider'
import { NoAuthProvider } from 'src/network/NoAuthProvider'
import MethodTypes from 'src/models/enums/MethodTypes'
import Route, { formatPathWithId } from 'src/network/Route'
import { sharedDataStore } from 'src/store/DataStore'
import { SelectOption } from 'src/models/Config'
import { formatDateTime, getChangedValues } from 'src/utils/format'
import { sharedQueryClient } from 'src/store/QueryClient'
import { IAxiosRetryConfig } from 'axios-retry'

const sortByMapValues = (items: string[], mapValues: IterableIterator<string>) => {
  const sorted = Array.from(mapValues)
  return items.sort((a, b) => sorted.indexOf(a) - sorted.indexOf(b))
}

export class DataObject {
  id: any
  static OVERRIDE_MAPPINGS = {}
  static apiEndpoint: string = '/'
  static primaryKey: string = 'id'
  static authRequired: boolean = true
  static shouldUseCache: boolean = false

  filterUpdates(this) {
    return {}
  }

  static getAPIProvider(this) {
    return this.authRequired && !sharedDataStore.user.isAnonymous() ? FlockjayProvider : NoAuthProvider
  }

  static formatEndpoint(this, urlParams?: Object) {
    if (urlParams === undefined) return this.apiEndpoint
    let path = this.apiEndpoint
    for (const key of Object.keys(urlParams)) {
      path = path.replace(`:${key}`, urlParams[key])
    }
    return path
  }

  static formatActionEndpoint(
    this,
    id?: string | null,
    urlParams?: Object,
    action?: string
  ): { path: string; method: MethodTypes } {
    const { path: basePath, method } = formatPathWithId(this.formatEndpoint(urlParams), id)
    return { path: action ? `${basePath}${action}/` : basePath, method }
  }

  getOverrideDisplayValueMappings = () => {
    return {}
  }

  static fromData(this, data) {
    if (Array.isArray(data)) {
      return data.map((item) => this.create(item))
    }
    return this.create(data)
  }

  static create<T extends typeof DataObject>(this: T, data) {
    let c = new this()
    this.copyValues(c, data, this.OVERRIDE_MAPPINGS)
    return c as InstanceType<T>
  }

  static copyValues(obj, data, overrides) {
    ;[...Object.keys(obj), ...Object.keys(obj.__proto__)].forEach((key) => {
      if (typeof obj[key] === 'function') return
      let overrideValue = overrides[key]
      if (overrideValue) {
        if (typeof overrideValue === 'function') {
          obj[key] = overrideValue(data)
        } else {
          obj[key] = data[overrideValue]
        }
      } else {
        if (
          [
            'createdAt',
            'lastUpdated',
            'date',
            'startDate',
            'endDate',
            'dueDate',
            'publishedAt',
            'meetingTime',
            'expiryDate',
            'progressExpiryDate',
          ].includes(key)
        ) {
          obj[key] = data[key] ? moment(data[key]) : undefined
        } else {
          obj[key] = data[key]
        }
      }
    })
  }

  update = (data: any) => {
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined) {
        this[key] = data[key]
      }
    })
  }

  getFieldDisplay = (field: string, sortByConfig: boolean = false) => {
    const override = this.getOverrideDisplayValueMappings()[field] || field
    const config = sharedDataStore.config[override] as SelectOption
    if (Array.isArray(this[field])) {
      if (config) {
        let displayValues = this[field].map((s) => config.get(s) || s)
        if (sortByConfig) displayValues = sortByMapValues(displayValues, config.values())
        return displayValues.join(Delimiters.bullet)
      } else {
        return this[field].join(Delimiters.bullet)
      }
    }
    if (typeof this[field] === 'string' && config) return config.get(this[field]) || this[field]
    return this[field]
  }

  getDateStr = (field: string, displayFormat?: string) =>
    this[field] ? formatDateTime(this[field], displayFormat) : ''

  pluralizeField = (field: string) => {
    const val = this[field]
    if (val && typeof val === 'string') {
      return val.charAt(val.length - 1).toLowerCase() === 's' ? `${val}'` : `${val}'s`
    }
    return val
  }

  static async get(
    this,
    id?: string,
    urlParams?: Object,
    actionName?: string,
    queryParams?: Object,
    abortController?: AbortController
  ) {
    const { path } = this.formatActionEndpoint(id, urlParams, actionName)
    const { data } = await this.getAPIProvider()(
      new Route(MethodTypes.GET, path, undefined, undefined, queryParams, abortController)
    )
    return this.fromData(data)
  }

  static async list(
    this,
    queryParams?: Object,
    urlParams?: Object,
    actionName?: string,
    customEndpoint?: string,
    abortController?: AbortController,
    useCache?: boolean,
    axiosRetryConfig?: IAxiosRetryConfig
  ) {
    let path = customEndpoint
    if (!customEndpoint) {
      const endpoint = this.formatActionEndpoint(undefined, urlParams, actionName)
      path = endpoint.path
    }

    const fetch = async () => {
      const { data } = await this.getAPIProvider()(
        new Route(MethodTypes.GET, path, {}, {}, queryParams, abortController, axiosRetryConfig)
      )
      if (data.results && Array.isArray(data.results)) return { ...data, data: this.fromData(data.results) }
      return this.fromData(data)
    }

    const shouldUseCache = useCache !== undefined ? useCache : this.shouldUseCache
    if (shouldUseCache) {
      return await sharedQueryClient.fetchQuery({
        queryKey: [path, queryParams],
        queryFn: fetch,
      })
    }

    return await fetch()
  }

  async httpOptions(this) {
    const { path } = this.constructor.formatActionEndpoint(this[this.constructor.primaryKey], { ...this })
    const { data } = await this.constructor.getAPIProvider()(new Route(MethodTypes.OPTIONS, path))
    return data
  }

  async save(
    this,
    newData?: Object,
    updateObject: boolean = true,
    partial: boolean = false,
    options?: Object,
    invalidateCache = true
  ) {
    const { path, method } = this.constructor.formatActionEndpoint(this[this.constructor.primaryKey], { ...this })
    let requestBody = newData === undefined ? this.filterUpdates() : newData
    requestBody = partial ? getChangedValues(requestBody, this) : requestBody
    if (Object.keys(requestBody).length === 0) return
    const response = await this.constructor.getAPIProvider()(new Route(method, path, requestBody, undefined, options))
    if (updateObject) this.constructor.copyValues(this, response.data, this.constructor.OVERRIDE_MAPPINGS)
    if (invalidateCache)
      await sharedQueryClient.invalidateQueries({ queryKey: [this.constructor.apiEndpoint], refetchType: 'none' })
    return response
  }

  async delete(this, invalidateCache = true) {
    const { path } = this.constructor.formatActionEndpoint(this[this.constructor.primaryKey], { ...this })
    await this.constructor.getAPIProvider()(new Route(MethodTypes.DELETE, path))
    if (invalidateCache)
      await sharedQueryClient.invalidateQueries({ queryKey: [this.constructor.apiEndpoint], refetchType: 'none' })
  }

  async post(
    this,
    actionName: string,
    data?: Object,
    updateObject: boolean = false,
    customEndpoint?: string,
    queryParams?: Object
  ) {
    let path = customEndpoint
    if (!customEndpoint) {
      path = this.constructor.formatActionEndpoint(this[this.constructor.primaryKey], { ...this }, actionName).path
    }
    const { data: responseData } = await this.constructor.getAPIProvider()(
      new Route(MethodTypes.POST, path, data, undefined, queryParams)
    )
    if (responseData && updateObject)
      this.constructor.copyValues(this, responseData, this.constructor.OVERRIDE_MAPPINGS)
    return responseData
  }

  async patch(this, actionName: string, data?: Object, updateObject: boolean = true) {
    const { path } = this.constructor.formatActionEndpoint(this[this.constructor.primaryKey], { ...this }, actionName)
    const { data: responseData } = await this.constructor.getAPIProvider()(new Route(MethodTypes.PATCH, path, data))
    if (responseData && updateObject) {
      this.constructor.copyValues(this, responseData, this.constructor.OVERRIDE_MAPPINGS)
    }
    return responseData
  }
}
