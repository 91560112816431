import { Formik } from 'formik'
import { Form } from 'formik-antd'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  ContainerDiv,
  ContentDataOptionType,
  DefaultButton,
  FJStyledInput,
  FjFormItem,
  FormHeaderText,
  LoadingAutoComplete,
  ViewState,
  ViewWrapper,
} from 'src/components/Common'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { SearchFeedContentCard } from 'src/components/Feed/SearchFeedContent'
import { Milestone } from 'src/models/Milestone'
import HTMLExpandablePreview from 'src/components/Common/HTMLExpandablePreview'

export interface ISelectMilestoneModalProps {
  onCancel: () => void
  onSuccess: (milestone: Milestone) => void
}

export const SelectMilestoneModal: React.FC<ISelectMilestoneModalProps> = ({ onSuccess }) => {
  const [autoCompleteLoading, setAutoCompleteLoading] = useState(false)
  const [milestones, setMilestones] = useState<Milestone[]>([])
  const [inputValue, setInputValue] = useState<string | undefined>()
  const inputRef = useRef<HTMLInputElement>()

  const milestoneOptions = useMemo(() => {
    return milestones.map((milestone: Milestone) => ({
      key: milestone.id,
      id: milestone.id,
      value: milestone.id,
      label: (
        <ContainerDiv
          display="flex"
          width={sharedAppStateStore.isMobile ? '300px' : '100%'}
          overflow="hidden"
          position="relative"
          whiteSpace="normal"
        >
          <SearchFeedContentCard key={milestone.id} obj={milestone} />
        </ContainerDiv>
      ),
      name: milestone.title,
    }))
  }, [milestones])

  const handleSearch = useCallback(
    async (search = '') => {
      try {
        setAutoCompleteLoading(true)
        const results = await Milestone.list({ search, is_active: true })
        setMilestones(results)
      } catch (err) {
        sharedAppStateStore.handleError(err)
        setMilestones([])
      } finally {
        setAutoCompleteLoading(false)
      }
    },
    [setAutoCompleteLoading]
  )

  const handleSubmit = useCallback(
    (option: ContentDataOptionType) => {
      const milestone = milestones.find((t) => t.id === option.value)
      onSuccess(milestone)
    },
    [milestones, onSuccess]
  )

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <ContainerDiv textAlign="left">
      <FormHeaderText heading="Select a Milestone" />
      <Formik initialValues={{}} onSubmit={() => {}}>
        <Form>
          <FjFormItem name="milestoneId">
            <ContainerDiv>
              <LoadingAutoComplete
                isLoadingOptions={autoCompleteLoading}
                defaultOpen
                placeholder={`Search for Milestones`}
                open
                onChange={(value) => setInputValue(value)}
                options={milestoneOptions}
                onSearch={handleSearch}
                onFocus={() => handleSearch('')}
                onSelect={(_, option: ContentDataOptionType) => handleSubmit(option)}
                style={{ width: sharedAppStateStore.isMobile ? '300px' : '500px' }}
                value={inputValue}
              >
                <FJStyledInput ref={inputRef} name="milestoneId" />
              </LoadingAutoComplete>
            </ContainerDiv>
          </FjFormItem>
        </Form>
      </Formik>
    </ContainerDiv>
  )
}

export const ViewMilestoneModal: React.FC<{ milestone: Milestone; handleCheckMilestoneProgress?: () => void }> = ({
  milestone,
  handleCheckMilestoneProgress,
}) => {
  const [viewState, setViewState] = useState<ViewState>('initialLoad')
  const [milestoneDescription, setMilestoneDescription] = useState<string | undefined>()

  const fetchMilestoneDescription = useCallback(async () => {
    try {
      setViewState('loading')
      const description = (await Milestone.get(milestone.id, { fields: 'description' })).description
      setMilestoneDescription(description)
      setViewState('idle')
    } catch (err) {
      setViewState('error')
      sharedAppStateStore.handleError(err, undefined, false)
    }
  }, [milestone])

  useEffect(() => {
    if (!milestone.description) fetchMilestoneDescription()
    else setMilestoneDescription(milestone.description)
  }, [milestone, fetchMilestoneDescription])

  return (
    <ViewWrapper viewState={viewState} errorStateProps={{ title: ' ', subtitleLine1: 'Failed to fetch milestone' }}>
      <HTMLExpandablePreview html={milestoneDescription} />
      {handleCheckMilestoneProgress ? (
        <ContainerDiv display="flex" justifyContent="end">
          <DefaultButton buttonType="primary" title="Refresh milestone status" clicked={handleCheckMilestoneProgress} />
        </ContainerDiv>
      ) : null}
    </ViewWrapper>
  )
}
