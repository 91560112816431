import React, { Suspense, lazy } from 'react'
import { Formik } from 'formik'
import { Form, Switch } from 'formik-antd'
import {
  ContainerDiv,
  FjQueryBuilderField,
  FjFormItem,
  FjInput,
  FormActionButtons,
  FormHeaderText,
  Loader,
  FjMemoFormItem,
} from 'src/components/Common'

import { Utils as QbUtils, AntdConfig, Config, FuncOrGroup } from '@react-awesome-query-builder/antd'
import '@react-awesome-query-builder/antd/css/compact_styles.css'
import { observer } from 'mobx-react'
import { observable, makeObservable, computed } from 'mobx'
import { Milestone } from 'src/models/Milestone'
import { ViewState, ViewWrapper } from 'src/components/Common/ViewWrapper'
import { isRequired } from 'src/utils/validation'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { sharedDataStore } from 'src/store/DataStore'

const FJCKEditor = lazy(() => import('src/components/Common/FJCKEditor'))

// Query builder demo: https://ukrbublik.github.io/react-awesome-query-builder/
// Demo config: https://github.com/ukrbublik/react-awesome-query-builder/blob/master/packages/examples/src/demo/config/index.tsx

// Reference for how to create functions: https://github.com/ukrbublik/react-awesome-query-builder/blob/master/packages/core/modules/config/funcs.js
const CUSTOM_RELATIVE_DATETIME = {
  label: 'Relative to Date',
  returnType: 'datetime',
  formatFunc: ({ offset, relSrc }, _) => `(${offset} days after ${relSrc})`,
  jsonLogic: ({ relSrc, offset }) => ({ '+': [{ var: relSrc }, `timedelta ${offset} days`] }),
  jsonLogicImport: (v) => {
    const offset = v['+'][1].split(' ')[1] // timedelta ${offset} days
    const relSrc = v['+'][0]['var']
    return [offset, relSrc]
  },
  renderBrackets: ['', ''],
  renderSeps: [' days after '],
  args: {
    offset: {
      label: 'Offset',
      type: 'number',
      valueSources: ['value'],
      suffix: 'days',
      fieldSettings: {
        step: 1,
      },
    },
    relSrc: {
      label: 'Relative Source',
      type: 'select',
      valueSources: ['value'],
      fieldSettings: {
        listValues: [{ value: 'user.start_date', title: "User's start date" }],
        allowCustomValues: false,
      },
    },
  },
}

const InitialConfig = AntdConfig

export interface IMilestoneFormProps {
  onSuccess: (milestone: Milestone) => Promise<void> | void
  onCancel: () => void
  milestone?: Milestone
}

@observer
class MilestoneForm extends React.Component<IMilestoneFormProps> {
  queryBuilderConfig: Config
  @observable viewState: ViewState = 'idle'

  @computed get isFormDisabled() {
    return !sharedDataStore.user.hasFlockjayEmail()
  }

  constructor(props: IMilestoneFormProps) {
    super(props)
    makeObservable(this)
    this.queryBuilderConfig = this.createQueryBuilderConfig()
  }

  componentDidCatch(error: Error) {
    sharedAppStateStore.handleError(error, undefined, false)
    this.viewState = 'error'
  }

  createQueryBuilderConfig = () => {
    const config = {
      ...InitialConfig,
      fields: {
        salesforce_opportunity: {
          type: '!struct',
          label: 'Opportunity',
          subfields: {
            created_at: {
              type: 'datetime',
              label: 'Created',
              valueSources: ['value', 'func'],
            },
            updated_date: {
              type: 'datetime',
              label: 'Updated Date',
              valueSources: ['value', 'func'],
            },
            closed_date: {
              type: 'datetime',
              label: 'Closed Date',
              valueSources: ['value', 'func'],
            },
            previous_stage: {
              type: 'select',
              label: 'Previous Stage',
              operators: ['select_equals'],
              valueSources: ['value'],
              fieldSettings: {
                listValues: sharedDataStore.config.opportunityStageNames.map((stage) => ({
                  value: stage,
                  title: stage,
                })),
                allowCustomValues: false,
              },
            },
            current_stage: {
              type: 'select',
              label: 'Next Stage',
              operators: ['select_equals'],
              valueSources: ['value'],
              fieldSettings: {
                listValues: sharedDataStore.config.opportunityStageNames.map((stage) => ({
                  value: stage,
                  title: stage,
                })),
                allowCustomValues: false,
              },
            },
            owner_id: {
              type: 'select',
              label: 'Owner',
              operators: ['select_equals'],
              valueSources: ['field'],
            },
            salesforce_user: {
              type: 'select',
              label: 'Salesforce User',
              operators: ['select_equals'],
              valueSources: ['field'],
            },
            is_won: {
              type: 'boolean',
              label: 'Is Won',
              operators: ['equal'],
              valueSources: ['value'],
            },
          },
        },
      },

      funcs: {
        datetime: {
          type: '!struct',
          label: 'Date',
          subfields: {
            CUSTOM_RELATIVE_DATETIME,
          },
        } as FuncOrGroup,
      },

      settings: {
        ...InitialConfig.settings,
        maxNesting: 1,
        showNot: false,
      },
    }

    return config
  }

  getInitialValues = () => {
    const { milestone } = this.props
    return {
      title: milestone?.title || undefined,
      isActive: milestone ? milestone.isActive : true,
      description: milestone?.description || undefined,
      queryTree: this.props.milestone ? QbUtils.loadTree(milestone.queryTree) : undefined,
    }
  }

  handleSubmit = async (formData: any) => {
    try {
      const { queryTree } = formData
      const milestone = this.props.milestone ? Milestone.fromData({ ...this.props.milestone }) : new Milestone()
      const data = {
        ...formData,
        preCondition: QbUtils.jsonLogicFormat(queryTree, this.queryBuilderConfig).logic,
        queryTree: QbUtils.getTree(queryTree),
      }
      await milestone.save(data, true, true)
      this.props.onSuccess(milestone)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  render() {
    const { milestone } = this.props
    return (
      <ContainerDiv textAlign="left" id="queryForm">
        <FormHeaderText heading={milestone ? `Edit Milestone: ${milestone.title}` : 'Create Milestone'} />
        <ViewWrapper viewState={this.viewState} errorStateProps={{ title: 'Error!' }}>
          <Formik initialValues={this.getInitialValues()} onSubmit={this.handleSubmit}>
            <Form disabled={this.isFormDisabled}>
              <ContainerDiv display="flex" alignItems="center" gap="20px">
                <FjFormItem fieldtitle="Title*" name="title" validate={isRequired} style={{ width: '80%' }}>
                  <FjInput name="title" placeholder="Title" />
                </FjFormItem>
                <FjFormItem fieldtitle="Active" name="isActive" style={{ margin: '0 auto' }}>
                  <Switch name="isActive" />
                </FjFormItem>
              </ContainerDiv>
              <FjMemoFormItem name="description" fieldtitle="Description" className="description-editor">
                <Suspense fallback={<Loader />}>
                  <FJCKEditor
                    name="description"
                    placeholder="Add some context about what it how to achieve this milestone..."
                    disabled={this.isFormDisabled}
                  />
                </Suspense>
              </FjMemoFormItem>
              <FjFormItem name="queryTree">
                <FjQueryBuilderField name="queryTree" config={this.queryBuilderConfig} />
              </FjFormItem>
              <FormActionButtons submitButtonLabel="Save" />
            </Form>
          </Formik>
        </ViewWrapper>
      </ContainerDiv>
    )
  }
}

export const LazyMilestoneForm = (props: IMilestoneFormProps) => {
  return (
    <Suspense fallback={<Loader />}>
      <MilestoneForm {...props} />
    </Suspense>
  )
}
