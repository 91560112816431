import React, { Component } from 'react'
import moment from 'moment'
import { observer } from 'mobx-react'
import {
  FormHeaderText,
  ContainerDiv,
  FjCheckBox,
  FjText,
  DefaultButton,
  FjFormItem,
  FjDatePicker,
  FjCheckboxGroup,
  Loader,
} from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { computed, observable, makeObservable } from 'mobx'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { Formik } from 'formik'
import { Form } from 'formik-antd'
import { Divider, Tabs } from 'antd'
import { showNotification } from 'src/hoc/Notification'
import { sharedDataStore } from 'src/store/DataStore'
import { getFeedContentTitle } from 'src/utils/content'
import { QueueElement, QueueItem } from 'src/models/QueueItem'
import { Group } from 'src/models/Group'
import { getIntersection } from 'src/utils/format'
import { Send } from 'react-feather'
import { UserCheckboxGroup } from 'src/components/Feed/UserCheckboxGroup'
import { Asset } from 'src/models/Asset'
import { Call } from 'src/models/Call'
import { Course } from 'src/models/Course'
import { FeedPlaylist } from 'src/models/FeedPlaylist'
import { FeedPost } from 'src/models/FeedPost'
import { LearningPath } from 'src/models/LearningPath'
import { Prompt } from 'src/models/Prompt'

export interface AddToQueueFormProps {
  onSuccess(): void
  onCancel?(): void
  item: QueueElement
}

const DIVIDER_STYLES: React.CSSProperties = { margin: '2px 0px', height: '4px' }

@observer
export class AddToQueueForm extends Component<AddToQueueFormProps> {
  @observable isLoading: boolean = false
  @observable filteredGroups: Group[] = []
  formikRef: any
  groups: Group[] = []

  @computed get groupsOptionMap() {
    return new Map(this.filteredGroups.map((group) => [group.id, this.getGroupCheckboxDisplay(group)]))
  }

  constructor(props) {
    super(props)
    makeObservable(this)
    this.formikRef = React.createRef()
  }

  async componentDidMount() {
    const { item } = this.props
    if (
      item instanceof FeedPost ||
      item instanceof Prompt ||
      item instanceof Course ||
      item instanceof LearningPath ||
      item instanceof FeedPlaylist ||
      item instanceof Asset ||
      item instanceof Call
    ) {
      await this.fetchGroups()
      const groupsToShow = Group.getGroupsAndSubGroups(item.groupIds, this.groups).filter(
        (group) => !group.isAllPublicUsersGroup()
      )
      if (groupsToShow.length === 0) return
      this.filteredGroups = groupsToShow
    }
  }

  fetchGroups = async () => {
    try {
      this.isLoading = true
      this.groups = await Group.list({ expand: 'active_users' }, undefined, undefined, undefined, undefined, true)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    } finally {
      this.isLoading = false
    }
  }

  getGroupCheckboxDisplay = (group: Group) => {
    return (
      <ContainerDiv marginVertical>
        <FjText textAlign="left" fontWeight="semi-bold" wordBreak="break-word" color={Colors.abbey} marginLeft={'10px'}>
          {group.name}
        </FjText>
      </ContainerDiv>
    )
  }

  handleSubmit = async (data: any) => {
    try {
      let { userIds, groupIds, dueDate } = data
      if (![...userIds, ...groupIds].length) return

      this.isLoading = true
      await QueueItem.addToQueue(this.props.item, userIds, groupIds, dueDate ? dueDate : undefined)

      if (
        userIds.includes(sharedDataStore.user.id) ||
        (!sharedDataStore.user.isFaasAdmin() && getIntersection(sharedDataStore.user.groupIds, groupIds).length)
      ) {
        sharedDataStore.refreshQueue()
      }

      showNotification({ message: `${getFeedContentTitle(this.props.item)} has been successfully assigned!` })
      this.props.onSuccess()
    } catch (err) {
      sharedAppStateStore.handleError(err)
    } finally {
      this.isLoading = false
    }
  }

  selectAllGroup = () => {
    this.formikRef.current.setFieldValue(
      'groupIds',
      this.filteredGroups.map((group) => group.id)
    )
  }

  deselectIds = (type: 'user' | 'group') => {
    this.formikRef.current.setFieldValue(`${type}Ids`, [])
  }

  render() {
    return (
      <ContainerDiv>
        <FormHeaderText heading={`Add "${getFeedContentTitle(this.props.item)}" to Tasks`} />
        <Formik
          innerRef={this.formikRef}
          initialValues={{
            userIds: [],
            groupIds: [],
            dueDate: moment().add(7, 'days').set('hours', 23).set('minute', 59).set('seconds', 59),
          }}
          onSubmit={this.handleSubmit}
        >
          <Form>
            <Tabs
              defaultActiveKey="group"
              items={[
                {
                  key: 'group',
                  label: 'Groups',
                  children: (
                    <ContainerDiv>
                      <ContainerDiv marginBottom={'20px'} textAlign="left">
                        <FjCheckBox
                          name={`all-groups`}
                          style={{ display: 'flex', fontSize: '16px', margin: '10px 0' }}
                          onChange={(e) => (e.target.checked ? this.selectAllGroup() : this.deselectIds('group'))}
                        >
                          <FjText
                            fontSize="medium"
                            textAlign="left"
                            fontWeight="semi-bold"
                            wordBreak="break-word"
                            color={Colors.abbey}
                            marginLeft={'10px'}
                          >
                            Select All
                          </FjText>
                        </FjCheckBox>
                      </ContainerDiv>
                      <Divider style={DIVIDER_STYLES} />
                      {this.isLoading ? (
                        <Loader containerStyle={{ height: 300 }} />
                      ) : (
                        <FjFormItem name="groupIds" style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                          <FjCheckboxGroup
                            name={`groupIds`}
                            optionsMap={this.groupsOptionMap}
                            checkboxStyle={{ width: '100%' }}
                            onChange={(ids) =>
                              this.formikRef.current.setFieldValue(
                                `all-groups`,
                                ids.length === this.filteredGroups.length
                              )
                            }
                            style={{ width: '100%' }}
                          />
                        </FjFormItem>
                      )}
                    </ContainerDiv>
                  ),
                },
                {
                  key: 'user',
                  label: 'Users',
                  children: <UserCheckboxGroup name="userIds" item={this.props.item} />,
                },
              ]}
            />
            <Divider style={DIVIDER_STYLES} />
            <FjFormItem name="dueDate">
              Due Date:{' '}
              <FjDatePicker
                name="dueDate"
                format="MMM Do, YYYY"
                showTime={false}
                style={{ marginLeft: '5px' }}
                disabledDate={(date) => date < moment().endOf('day').subtract(1, 'day')}
              />
              <FjText> 11:59 PM PST</FjText>
            </FjFormItem>
            <ContainerDiv display="flex" justifyContent="right">
              <DefaultButton
                type="submit"
                buttonType="primary"
                image={
                  <Send
                    color={Colors.white}
                    size={16}
                    style={{
                      pointerEvents: 'none',
                    }}
                  />
                }
                title="Submit"
              />
            </ContainerDiv>
          </Form>
        </Formik>
      </ContainerDiv>
    )
  }
}
